<template>
  <div>
    <div v-show="false">
      <type-settings :isEditMode="false" modelid="costsPosition" modelType="project" type="Kosten-Verursacher" />
    </div>
    <div class="card card-user">
      <div class="card-header">
        <h6 class="title text-center">
          {{ $t("src.components.project.projectcontrolling.projectcontrolling.projektControlling") }}
        </h6>
      </div>
      <div class="card-body">
        <project-fulfillment
          :projectId="projectId"
          :isConstructionPhase="isConstructionPhase"
          :isEditMode="computedEditMode"
          :projectDateRange="projectDateRange"
        />
        <h6>{{ $t("src.components.project.projectcontrolling.projectcontrolling.costs") }}</h6>
        <costs
          :projectId="projectId"
          :isEditMode="computedEditMode"
          :providersOptions="providersOptions"
          :positionsOptions="positionsOptions"
          :costs="costs"
          :projectsMeta="projectsMeta"
          :accessRights="accessRights"
        />
        <!-- <h6>{{ $t("src.components.project.projectcontrolling.projectcontrolling.zukufe") }}</h6>
        <purchases
          :projectId="projectId"
          :isEditMode="computedEditMode"
          :purchases="purchases"
          :providersOptions="providersOptions"
          :positionsOptions="positionsOptions"
          :projectsMeta="projectsMeta"
          :accessRights="accessRights"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Collapse, CollapseItem, Keyfigures } from "src/components/UIComponents";
import Costs from "./Costs";
import Purchases from "./Purchases";
import { mapState } from "vuex";
import ProjectFulfillment from "./ProjectFulfillment";
import { TypeSettings } from "src/components/UIComponents";

export default {
  name: "project-controlling",
  props: {
    isEditMode: { type: Boolean, required: true },
    accessRights: { type: String },
    projectId: { type: String },
    isConstructionPhase: { type: Boolean },
    projectsMeta: { type: Array },
    projectDateRange: { type: Array },
  },
  components: {
    TypeSettings,
    ProjectFulfillment,
    [Costs.name]: Costs,
    [Keyfigures.name]: Keyfigures,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Purchases.name]: Purchases,
  },
  data: function () {
    return {
      providersOptions: [],
      positionsOptions: [],
      costs: [],
      purchases: [],
      // unitsOptions: []
    };
  },
  async mounted() {
    this.$root.$on("settingsChanged", (data) => {
      console.log("data", data);
      if (data.modelType === "project" && data.modelID === "costsPosition") {
        this.positionsChanged(data);
      }
    });
    await Promise.all([
      this.fetchData(),
      this.fetchProvidersOptions(),
      // , this.fetchUnitsOptions()
    ]);
  },
  methods: {
    positionsChanged(newData) {
      this.positionsOptions = newData.options.map((item) => ({ value: item._id, label: item.label }));
    },
    async fetchProvidersOptions() {
      try {
        const customOptions = await this.axios
          .get("/api/model-type", {
            params: {
              modelType: "serviceProvider",
              modelId: "serviceProviderType",
            },
          })
          .then((res) => res.data)
          .then((suppliers) => suppliers.map((item) => ({ value: item._id, label: item.label })));
        this.providersOptions = this.defaultServiceProviders.defaultOptions.concat(
          {
            value: "SUPPLIER",
            label: "Lieferanten",
          },
          {
            value: "SUBCONTRACTOR",
            label: "Nachunternehmer",
          },
          customOptions
        );
      } catch (error) {
        throw error;
      }
    },
    async fetchUnitsOptions() {
      try {
        const unitsOptions = await this.axios
          .get("/api/model-type", {
            params: {
              modelType: "supply",
              modelId: "unit",
            },
          })
          .then((res) => res.data)
          .then((suppliers) => suppliers.map((item) => ({ value: item._id, label: item.label })));
        this.unitsOptions = unitsOptions;
      } catch (error) {
        throw error;
      }
    },
    async fetchData() {
      try {
        const self = this;
        const projectId = this.projectId;
        const [costsResponse, purchasesResponse] = await Promise.all([
          self.axios.get(`/api/project-costs/${projectId}`),
          self.axios.get(`/api/project-purchases/${projectId}`),
        ]);
        this.costs = costsResponse.data;
        this.purchases = purchasesResponse.data;
      } catch (error) {
        throw error;
      }
    },
  },
  computed: {
    // default service providers defined in vuex store to be reusable in other parts of app
    ...mapState(["defaultServiceProviders"]),
    computedEditMode() {
      return this.isEditMode && (this.accessRights === "full" || this.accessRights === "manage");
    },
  },
};
</script>

<style></style>

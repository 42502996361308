<template>
  <el-dialog
    :visible="isVisible"
    @close="handleClickClose"
    title="Status Kopieren"
    @closed="discard"
    :close-on-click-modal="false"
  >
    <div class="flex align-items-center">
      <profile-select
        v-model="selectedResources"
        title="Ressourcen"
        :items="availableResources"
        filterable
        editMode
        style="flex-grow: 1"
      /><el-button style="flex-shrink: 0; margin-left: 10px" @click="selectAll">{{ $t("selectAll") }}</el-button>
    </div>
    <el-alert type="error" :title="alertTitle" v-if="erroredResourceNames.length">
      <ul class="mb-1">
        <li v-for="name in erroredResourceNames" :key="name">
          {{ name }}
        </li>
      </ul>
      {{ $t("copyStatusManually") }}
    </el-alert>
    <template v-slot:footer>
      <div class="dialog-footer">
        <el-button @click="discard">{{ $t("src.components.machine.customerservice.abbrechen") }}</el-button>
        <el-button
          type="primary"
          :loading="isLoading"
          :disabled="!selectedResources.length"
          @click.prevent="handleSubmit"
        >
          {{ submitWasTriggered ? "Bestätigen" : "Speichern" }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { Dialog, Message, Alert, MessageBox } from "element-ui";
import { moment } from "src/config/moment";
import ProfileSelect from "../../UIComponents/Inputs/ProfileSelect.vue";
import { getGranularityMode } from "src/utils/getGranularityMode";
import { EventModel } from "@bryntum/scheduler";

const resourceTypeToGerman = {
  employee: "Mitarbeiter",
  machine: "Maschine",
  vehicle: "KFZ",
  rhb: "RHB",
};

export default {
  name: "copy-event-editor",
  props: {
    getResourcesByResourceType: { type: Function },
    ignoreNextUpdateCheck: Function,
  },
  components: {
    ProfileSelect,
    [Alert.name]: Alert,
    [Dialog.name]: Dialog,
    [Message.name]: Message,
  },
  mounted() {
    this.$root.$on("copyEvent", this.handleCopyStatusEvent);
  },
  beforeDestroy() {
    this.$root.$off("copyEvent");
  },
  data() {
    return {
      isVisible: false,
      isLoading: false,
      submitWasTriggered: false,
      eventRecord: null,
      selectedResources: [],
      availableResources: [],
      erroredResourceNames: [],
    };
  },
  methods: {
    async handleSubmit() {
      let results;
      try {
        this.submitWasTriggered = true;
        this.isLoading = true;
        this.$props.ignoreNextUpdateCheck();
        let url,
          formData = {
            start: moment(this.eventRecord.startDate).format("YYYY-MM-DD"),
            end: moment(this.eventRecord.endDate).format("YYYY-MM-DD"),
            resourceType: this.eventRecord.resourceType,
          };
        if (this.eventRecord.projectId) {
          url = "/api/project-events";
          formData.projectId = this.eventRecord.projectId;
        } else {
          url = "/api/status-events";
          formData.statusType = this.eventRecord.statusEvent._id;
        }
        const requests = this.selectedResources.map((resourceId) =>
          this.axios
            .post(
              url,
              { ...formData, resourceId },
              {
                params: { mode: getGranularityMode(formData.resourceType) },
              }
            )
            .catch((e) => e)
        );
        results = await Promise.all(requests);
        const erroredRequests = results.filter((request) => request.isAxiosError);
        if (results.length > erroredRequests.length) {
          results
            .filter((request) => !request.isAxiosError)
            .map(({ data: event }) => {
              window.resourceScheduler.eventStore.add(
                new EventModel({
                  id: event.id,
                  dbEventId: event.id,
                  _id: event.id,
                  name: event.statusType.label,
                  resourceType: event.resourceType,
                  resourceId: event.resourceId,
                  startDate: event.start,
                  start: event.start,
                  end: event.end,
                  endDate: event.end,
                  eventColor: event.statusType.color, // is set for status events
                  iconCls: event.statusType ? event.statusType.icon : undefined,
                  statusEvent: event.statusType,
                  draggable: true,
                })
              );
              this.$emit("eventAdded", event);
            });
        }
        if (erroredRequests.length) {
          throw erroredRequests;
        }
        Message.success("Status erstellt");
        this.discard();
      } catch (errors) {
        if (Array.isArray(errors)) {
          const requestBodies = errors.map((item) => JSON.parse(item.config.data));
          const resourceNames = requestBodies.map(({ resourceId }) => {
            const resourceRecord = this.availableResources.find((resource) => resource.value === resourceId);
            return resourceRecord.label;
          });
          this.erroredResourceNames = resourceNames;
        } else {
          throw errors;
        }
      } finally {
        this.isLoading = false;
      }
    },
    selectAll() {
      this.selectedResources = this.availableResources.map(({ value }) => value);
    },
    handleCopyStatusEvent(eventRecord) {
      this.isVisible = true;
      this.eventRecord = { ...eventRecord };
      const resources = this.$props.getResourcesByResourceType(eventRecord.resourceType);
      this.availableResources = resources
        .filter((item) => item.active && item.id !== eventRecord.resourceId)
        .map((item) => ({ value: item.id, label: item.title }));
    },
    handleClickClose() {
      if (this.erroredResourceNames.length) {
        MessageBox.confirm("Sind Sie sicher?", "Achtung", {
          confirmButtonText: "Ja",
          cancelButtonText: "Nein",
          type: "warning",
          cancelButtonClass: "el-button--danger",
          confirmButtonClass: "button-default",
        }).then(async () => {
          this.isVisible = false;
        });
      } else {
        this.isVisible = false;
      }
    },
    discard() {
      this.isVisible = false;
      this.submitWasTriggered = false;
      this.eventRecord = null;
      this.selectedResources = [];
      this.availableResources = [];
      this.erroredResourceNames = [];
    },
  },
  computed: {
    alertTitle() {
      if (this.eventRecord) {
        const resourceName = resourceTypeToGerman[this.eventRecord.resourceType];
        return `Für folgende ${resourceName} konnte kein Veranstaltung kopiert werden`;
      } else {
        return "";
      }
    },
  },
};
</script>

<style></style>

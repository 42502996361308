<template>
  <div
    style="width: 100%"
    :class="{
      'ro-cal':
        accessRightsPerResourceType[activeCalendar] === 'readonly' || !accessRightsPerResourceType[activeCalendar],
      'ro-proj': !$can('create', 'project') || !calendarAccess.specificAccess.quickProjectSetup,
      'hide-project-info': !calendarAccess.specificAccess.infoDownload,
      'detach-mode': $route.query.detachMode === 'true',
      'kiosk-mode': isKioskMode,
    }"
  >
    <picture-preview ref="picturePreview" />
    <div class="topbar-filters">
      <div>
        <!-- view for detached project -->
        <!-- <div v-if="projectMode">
          <div>
            <el-switch
              v-model="isSyncViewDate"
              active-color="#18ce68"
              inactive-color="#ff6868"
              style="margin-right: 5px"
            />
            <b>{{ isSyncViewDate ? "Synchron" : "Asynchron" }}</b>
          </div>
        </div>
        <div v-else> -->
        <div v-if="!projectMode">
          <el-dropdown @command="handleChangeCalendar" style="margin-right: 10px">
            <span class="calendar-link"> {{ dropdownLabel }}<i class="b-fa b-fa-chevron-down b-fa-sm"></i> </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="employee" v-if="calendarAccess.specificAccess.employeeCalendarAccess">{{
                $t("src.components.project.bryntumscheduler.bryntumscheduler.mitarbeiter")
              }}</el-dropdown-item>
              <el-dropdown-item command="vehicle" v-if="calendarAccess.specificAccess.vehicleCalendarAccess">{{
                $t("src.components.project.bryntumscheduler.bryntumscheduler.kfz")
              }}</el-dropdown-item>
              <el-dropdown-item command="machine" v-if="calendarAccess.specificAccess.machineCalendarAccess">{{
                $t("src.components.project.bryntumscheduler.bryntumscheduler.maschinen")
              }}</el-dropdown-item>
              <el-dropdown-item command="rhb" v-if="calendarAccess.specificAccess.rhbCalendarAccess">{{
                $t("src.components.project.bryntumscheduler.bryntumscheduler.rhb")
              }}</el-dropdown-item>
              <el-dropdown-item command="supply" v-if="calendarAccess.specificAccess.supplyCalendarAccess">{{
                $t("src.components.project.bryntumscheduler.bryntumscheduler.verbrauchsm")
              }}</el-dropdown-item>
              <el-dropdown-item
                command="subcontractor"
                v-if="calendarAccess.specificAccess.subcontractorCalendarAccess"
                >{{ $t("src.components.project.bryntumscheduler.bryntumscheduler.subcontractor") }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown
            v-if="activeCalendar === 'employee'"
            @command="debounceHandleChangeVisibleRole"
            :hide-on-click="false"
            style="margin-right: 10px"
          >
            <span class="calendar-link" style="font-size: 1rem"
              >{{ $t("src.components.project.bryntumscheduler.bryntumscheduler.mitarbeiterRolle") }}{{ selectedRoles
              }}<i class="b-fa b-fa-chevron-down b-fa-sm"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="Bauleitung">
                <el-checkbox :value="employeeRoleFilters['Bauleitung']">{{
                  $t("src.components.project.bryntumscheduler.bryntumscheduler.bauleitung")
                }}</el-checkbox>
              </el-dropdown-item>
              <el-dropdown-item command="Gewerblich">
                <el-checkbox :value="employeeRoleFilters['Gewerblich']">{{
                  $t("src.components.project.bryntumscheduler.bryntumscheduler.gewerblich")
                }}</el-checkbox>
              </el-dropdown-item>
              <el-dropdown-item command="Verwaltung">
                <el-checkbox :value="employeeRoleFilters['Verwaltung']">{{
                  $t("src.components.project.bryntumscheduler.bryntumscheduler.verwaltung")
                }}</el-checkbox>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div>
        <el-dropdown
          v-if="resourceDropdownVisible"
          @command="debounceHandleResouceFilter"
          :hide-on-click="false"
          class="mr-3"
          trigger="click"
        >
          <span class="calendar-link" style="font-size: 1rem"
            >{{ $t("src.components.project.bryntumscheduler.bryntumscheduler.ressourcen")
            }}<i class="b-fa b-fa-chevron-down b-fa-sm"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="all">
              <el-checkbox :indeterminate="isAllIntermediate" :value="isAllChecked">{{
                $t("src.components.project.bryntumscheduler.bryntumscheduler.alle")
              }}</el-checkbox>
            </el-dropdown-item>
            <el-dropdown-item command="employee" v-if="calendarAccess.specificAccess.employeeCalendarAccess">
              <el-checkbox :value="!projectResourceFilters.employee" :checked="!projectResourceFilters.employee">{{
                $t("src.components.project.bryntumscheduler.bryntumscheduler.mitarbeiter")
              }}</el-checkbox>
            </el-dropdown-item>
            <el-dropdown-item command="vehicle" v-if="calendarAccess.specificAccess.vehicleCalendarAccess">
              <el-checkbox :value="!projectResourceFilters.vehicle" :checked="!projectResourceFilters.vehicle">{{
                $t("src.components.project.bryntumscheduler.bryntumscheduler.kfz")
              }}</el-checkbox>
            </el-dropdown-item>
            <el-dropdown-item command="machine" v-if="calendarAccess.specificAccess.machineCalendarAccess">
              <el-checkbox :value="!projectResourceFilters.machine" :checked="!projectResourceFilters.machine">{{
                $t("src.components.project.bryntumscheduler.bryntumscheduler.maschinen")
              }}</el-checkbox>
            </el-dropdown-item>
            <el-dropdown-item command="rhb" v-if="calendarAccess.specificAccess.rhbCalendarAccess">
              <el-checkbox :value="!projectResourceFilters.rhb" :checked="!projectResourceFilters.rhb">{{
                $t("src.components.project.bryntumscheduler.bryntumscheduler.rhb")
              }}</el-checkbox>
            </el-dropdown-item>
            <el-dropdown-item command="supply" v-if="calendarAccess.specificAccess.supplyCalendarAccess">
              <el-checkbox :value="!projectResourceFilters.supply" :checked="!projectResourceFilters.supply">{{
                $t("src.components.project.bryntumscheduler.bryntumscheduler.verbrauchsm")
              }}</el-checkbox>
            </el-dropdown-item>
            <el-dropdown-item command="subcontractor" v-if="calendarAccess.specificAccess.subcontractorCalendarAccess">
              <el-checkbox
                :value="!projectResourceFilters.subcontractor"
                :checked="!projectResourceFilters.subcontractor"
                >{{ $t("src.components.project.bryntumscheduler.bryntumscheduler.subcontractor") }}</el-checkbox
              >
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-popover width="215" placement="bottom-left">
          <div>
            <div class="mb-3">
              <div class="n-profile-label-title mb-2">
                {{ $t("src.components.project.bryntumscheduler.bryntumscheduler.rowHeight") }}
              </div>
              <profile-radio-group
                v-model="calendarSize.rowHeight"
                :items="[
                  { label: 'Small', value: 0.8, disabled: calendarSize.textSize === 'lg' },
                  { label: 'Medium', value: 1 },
                  { label: 'Large', value: 1.2 },
                ]"
              />
            </div>
            <div>
              <div class="n-profile-label-title mb-2">
                {{ $t("src.components.project.bryntumscheduler.bryntumscheduler.textSize") }}
              </div>
              <profile-radio-group
                v-model="calendarSize.textSize"
                :items="[
                  { label: 'Small', value: 'sm' },
                  { label: 'Medium', value: 'md' },
                  { label: 'Large', value: 'lg', disabled: calendarSize.rowHeight === 0.8 },
                ]"
              />
            </div>
          </div>
          <el-button
            slot="reference"
            type="text"
            style="font-size: 1rem; padding: 0; font-weight: bold; color: #606266"
          >
            <tune-icon />{{ $t("src.components.project.bryntumscheduler.bryntumscheduler.tableSetup")
            }}<menu-down-icon />
          </el-button>
        </el-popover>
      </div>
    </div>
    <div
      :class="['b-scheduler-container', { canAddComments: canAddComments }, { 'overlapping-mode': eventsCanOverlap }]"
      id="scheduler-container"
      v-loading="loading"
    ></div>
    <hotel-booking-dialog
      ref="hotelBookingDialog"
      :getBookingRecord="dataManager.getBookingRecord"
      @updateBooking="updateProjectHotelBooking"
      @updated="triggerCalendarUpdate"
    />
    <project-event-create-editor
      :getResourcesForType="getResourceIdItems"
      :getAvailableProjects="getAvailableProjects"
      :currentMonday="dataManager.viewDateRange.startDate"
      :eventEditVisible="projectEventCreate.visible"
      :eventRecord="projectEventCreate.eventRecord"
      :getDbEvent="dataManager.getDbEvent"
      :resourceId="projectEventCreate.resourceId"
      :resourceType="projectEventCreate.resourceType"
      :resourceRecord="projectEventCreate.resourceRecord"
      :projectId="projectEventCreate.projectId"
      :visible="projectEventCreate.visible"
      @close="onCloseProjectEventCreate"
      :triggerCalendarUpdate="triggerCalendarUpdate"
      :eventsCanOverlap="eventsCanOverlap"
    ></project-event-create-editor>
    <project-event-edit
      :eventRecord="projectEventEdit.eventRecord"
      :getDbEvent="dataManager.getDbEvent"
      :resourceId="projectEventEdit.resourceId"
      :resourceType="projectEventEdit.resourceType"
      :resourceRecord="projectEventEdit.resourceRecord"
      :ignoreNextUpdateCheck="ignoreNextUpdateCheck"
      :visible="projectEventEdit.visible"
      @eventEdited="dataManager.editEvent"
      @close="onCloseEventEditor"
    ></project-event-edit>
    <status-event-create-editor
      :eventRecord="statusEventEdit.eventRecord"
      :getDbEvent="dataManager.getDbEvent"
      :resourceId="statusEventEdit.resourceId"
      :resourceType="statusEventEdit.resourceType"
      :resourceRecord="statusEventEdit.resourceRecord"
      :dayClicked="statusEventEdit.dayClicked"
      @close="onCloseEventEditor"
      @eventAdded="dataManager.addEvent"
      @eventEdited="dataManager.editEvent"
      :getIsDisabledStatusEvents="isDisabledStatusEvents"
      :eventEditVisible="statusEventEdit.visible"
      :ignoreNextUpdateCheck="ignoreNextUpdateCheck"
      :getCanCreateNewStatusTypes="getCanCreateNewStatusTypes"
    >
    </status-event-create-editor>
    <copy-event-editor
      :getResourcesByResourceType="dataManager.getResourcesByResourceType"
      @eventAdded="dataManager.addEvent"
      :ignoreNextUpdateCheck="ignoreNextUpdateCheck"
    />
    <montagsplanung
      :getProjectById="dataManager.getProjectById"
      :projectsSelection="dataManager.projectsSelection"
      :createProjectEvents="dataManager.addProjectEvents"
      :getResourcesByResourceType="dataManager.getResourcesByResourceType"
      :currentMonday="dataManager.viewDateRange.startDate"
    ></montagsplanung>
    <events-resolver :projectsSelection="dataManager.projectsSelection" />
    <project-info-modal
      :projects="dataManager.projectsList"
      @dismissModal="dismissProjectInfoModal"
      :isViewOnly="isKioskMode"
      :projectInfoId="projectInfoId"
      :employees="dataManager.resources.employee"
      :viewDateRange="dataManager.viewDateRange"
    />
    <delete-event-modal :onDeleteEvents="deleteEvents"></delete-event-modal>
    <quick-project-setup />
    <hotel-info ref="hotelInfo" :getHotelsForDay="dataManager.getHotelsForDay" />
    <project-cell-info ref="projectCellInfo" />
    <event-comment-dialog
      :visible="eventCommentVisible"
      :dataManager="dataManager"
      @dismissModal="dismissEventComment"
      @updated="triggerCalendarUpdate"
      :payload="eventCommentData"
      :canEditComment="canAddComments"
    />
    <copy-resources-dialog
      :visible="copyResourcesVisible"
      @dismissModal="dismissCopyResources"
      :project="copyResourcesProject"
      :projectsSelection="dataManager.projectsSelection"
      :defaultStartDate="dataManager.viewDateRange.startDate"
    />
    <event-quantity-dialog
      :visible="isQuantityModalVisible"
      :eventRecord="editingEventRecord"
      :resourceRecord="editingResourceRecord"
      :onSave="saveQuantity"
      :ignoreNextUpdateCheck="ignoreNextUpdateCheck"
      @close="onQuantityModalClose"
    />
  </div>
</template>

<script>
/**
 * @typedef {("employee" | "machine" | "vehicle" | "rhb" | "supply")} ResourceType
 */
import Vue from "vue";
import * as authImage from "./AuthImage";
import { Message, Button, Dropdown, DropdownItem, DropdownMenu, Checkbox, Switch, Popover } from "element-ui";
import { Scheduler, Splitter, StringHelper } from "@bryntum/scheduler";
import { mapActions } from "vuex";
import {
  baseConfig,
  employeeConfig,
  vehicleConfig,
  machineConfig,
  rhbConfig,
  supplyConfig,
  subcontractorConfig,
  projectConfig,
  timeControls,
  zoomControls,
  PROJECT_REORDER_EVENT,
} from "./schedulerConfig.js";
import TuneIcon from "vue-material-design-icons/Tune";
import MenuDownIcon from "vue-material-design-icons/MenuDown";
import DataManager from "./DataManager";
import { moment } from "src/config/moment";
import { ORIGIN } from "src/globalAxios";
import ProjectEventCreateEditor from "./ProjectEventCreateEditor";
import StatusEventCreateEditor from "./StatusEventCreateEditor";
import ProjectEventEdit from "./ProjectEventEdit";
import CopyEventEditor from "./CopyEventEditor.vue";
import Montagsplanung from "./Montagsplanung";
import EventsResolver from "./EventsResolver";
import ProjectInfoModal from "./ProjectInfoModal";
import HotelBookingDialog from "./HotelBookingDialog.vue";
import DeleteEventModal from "./DeleteEventModal";
import QuickProjectSetup from "./QuickProjectSetup";
import HotelInfo from "./HotelInfo";
import ProjectCellInfo from "./ProjectCellInfo";
import { debounce, get, sortBy } from "lodash";
import { mapGetters, mapState } from "vuex";
import EventCommentDialog from "./EventCommentDialog.vue";
import CopyResourcesDialog from "./CopyResourcesDialog.vue";
import EventQuantityDialog from "./EventQuantityDialog.vue";
import PicturePreview from "./PicturePreview.vue";
import { getGranularityMode } from "src/utils/getGranularityMode";
import { until } from "src/utils/until";

const DataManagerClass = Vue.extend(DataManager);
const RESOURCE_FILTERS_NAMESPACE = "calendar_resourceFilters";
const schedulerConfigMap = {
  employee: employeeConfig,
  machine: machineConfig,
  rhb: rhbConfig,
  vehicle: vehicleConfig,
  supply: supplyConfig,
  subcontractor: subcontractorConfig,
};
const resourceLabelMap = {
  employee: "Mitarbeiter",
  machine: "Maschinen",
  vehicle: "KFZ",
  rhb: "RHB",
  supply: "Verbrauchsm.",
  subcontractor: "Nachunternehmer",
  project: "Projekt",
};
const CAL_TEXT_SIZE = "CAL_TEXT_SIZE";
const CAL_ROW_HEIGHT = "CAL_ROW_HEIGHT";

export default {
  name: "bryntum-scheduler",
  components: {
    PicturePreview,
    DeleteEventModal,
    Message,
    Button,
    ProjectEventCreateEditor,
    StatusEventCreateEditor,
    ProjectEventEdit,
    HotelBookingDialog,
    CopyEventEditor,
    EventQuantityDialog,
    CopyResourcesDialog,
    EventsResolver,
    ProjectInfoModal,
    Montagsplanung,
    QuickProjectSetup,
    HotelInfo,
    ProjectCellInfo,
    TuneIcon,
    MenuDownIcon,
    EventCommentDialog,
    [Popover.name]: Popover,
    [Switch.name]: Switch,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Checkbox.name]: Checkbox,
  },
  data() {
    let preservedResourceFilters = {};
    const preservedResourceFiltersJson = localStorage.getItem(RESOURCE_FILTERS_NAMESPACE) || "{}";
    try {
      preservedResourceFilters = JSON.parse(preservedResourceFiltersJson);
    } catch (error) {
      console.error("Could not parse JSON: ", preservedResourceFiltersJson);
    }
    let EXPANDED_PROJECTS = {};
    try {
      const parsed = JSON.parse(localStorage.getItem("EXPANDED_PROJECTS"));
      if (parsed) {
        EXPANDED_PROJECTS = parsed;
      }
    } catch (error) {
      console.error("Could not parse EXPANDED_PROJECTS JSON: ", EXPANDED_PROJECTS);
    }
    return {
      dataManager: new DataManagerClass(),
      resourceDropdownVisible: true,
      eventCommentVisible: false,
      eventCommentData: {
        eventId: null,
        comment: null,
        resourceType: null,
      },
      copyResourcesVisible: false,
      copyResourcesProject: {
        id: null,
        dateRange: null,
        name: null,
      },
      unavailableHidden: true,
      projectEventCreate: {
        resourceRecord: null,
        resourceId: null,
        resourceType: null,
        eventRecord: null,
        projectId: null,
        visible: false,
      },
      statusEventEdit: {
        eventRecord: null,
        resourceId: null,
        resourceType: null,
        resourceRecord: null,
        visible: false,
        dayClicked: null,
      },
      projectEventEdit: {
        eventRecord: null,
        resourceId: null,
        resourceType: null,
        resourceRecord: null,
        visible: false,
      },
      loading: false,
      activeCalendar: "employee",
      availableCalendars: [],
      baseConfig: baseConfig,
      resourceIconsMap: {
        employee: "nc-icon nc-badge",
        machine: "nc-icon nc-headphones",
        rhb: "nc-icon nc-diamond",
        vehicle: "nc-icon nc-delivery-fast",
        supply: "nc-icon nc-box-2",
        subcontractor: "nc-icon nc-settings",
      },
      employeeRoleFilters: {
        Bauleitung: false,
        Gewerblich: true, // intially turned on
        Verwaltung: false,
      },
      projectResourceFilters: Object.assign(
        {},
        {
          employee: false,
          machine: false,
          vehicle: false,
          rhb: false,
          supply: false,
          subcontractor: false,
        },
        preservedResourceFilters
      ),
      expandedProjects: EXPANDED_PROJECTS, // keeps collapsed state of project calendar when changing timeranges
      socket: {},
      preventNextCheck: false, // used to prevent refresh for next checkForUpdates() call to prevent flickering
      projectInfoId: null,
      isSyncViewDate: true, // handles syncron daterange change between resource and project calendar
      calendarSize: {
        rowHeight: 1,
        textSize: "md",
      },
      socketIntervalId: null,
      isQuantityModalVisible: false,
      editingEventRecord: null,
      editingResourceRecord: null,
    };
  },
  created() {
    this.debounceHandleChangeVisibleRole = debounce(this.handleChangeVisibleRole, 100);
    this.debounceHandleResouceFilter = debounce(this.handleResouceFilter, 100);
    this.debounceSetDateRange = debounce(this.setDateRange, 50);
    this.handleTimeAxisChange = debounce(this._handleTimeAxisChange, 300);
    // set preserved calendar size settings
    const savedTextSize = localStorage.getItem(CAL_TEXT_SIZE);
    const savedRowHeight = localStorage.getItem(CAL_ROW_HEIGHT);
    if (savedTextSize) {
      this.calendarSize.textSize = savedTextSize;
    }
    if (savedRowHeight) {
      this.calendarSize.rowHeight = savedRowHeight;
    }
  },
  async mounted() {
    // have to be first to setup initial daterange in time
    this.handleSetDateFromQuery();
    window.addEventListener("message", this.receiveMessage, false);
    document.addEventListener("click", this.resourceLinkClickHandler, false);
    if (this.isKioskMode) {
      employeeConfig.columns = employeeConfig.columns.filter((column) => column.field !== "position");
    }
    // document.addEventListener("mouseover", this.handleLoadTooltip, false);
    await until(() => this.accessRightsLoaded);
    const { calendarConfig, resourceType } = this.getFirstAvailableCalendar();
    this.dataManager.setAccessRightsPerResourceType(this.accessRightsPerResourceType);
    this.activeCalendar = resourceType;
    if (!this.projectMode) {
      this.initScheduler(calendarConfig, "resourceScheduler", resourceType);
      window.calendarSplitterRef = new Splitter({
        appendTo: "scheduler-container",
      });
      this.setupChecklist();
      window.resourceScheduler.mask({ text: "Laden..." });
    }
    await this.dataManager.fetchProjects();
    await this.dataManager.fetchAllResources();
    if (!this.projectMode) {
      // in projectMode we initialize only project calendar
      const eventColor = this.colorSettings[resourceType];
      const resources = await this.dataManager.fetchCalendarData(resourceType, eventColor, true);
      window.resourceScheduler.resourceStore.data = resources;
      const resourceTimeRanges = this.makeResourceTimeRanges(resources);
      window.resourceScheduler.resourceTimeRangeStore.add(resourceTimeRanges);
      window.resourceScheduler.unmask();
    } else {
      await this.dataManager.fetchEvents(
        this.activeCalendar,
        this.dataManager.viewDateRange.startDate,
        this.dataManager.viewDateRange.endDate
      );
      // register event listener that when project calendar window is closed - return in back at main window.
      window.addEventListener("beforeunload", (e) => {
        window.opener.postMessage({ type: "DETACHED_CALENDAR_CLOSED" }, window.location.origin);
      });
    }
    if (this.isKioskMode) {
      const granularityWidget =
        window.resourceScheduler && window.resourceScheduler.getWidgetById("granularity_widget");
      if (granularityWidget) {
        granularityWidget.disabled = true;
      }
    } else {
      const busyDaysFilter = window.resourceScheduler && window.resourceScheduler.getWidgetById("busyDaysFilter");
      if (busyDaysFilter) {
        busyDaysFilter.disabled = false;
        busyDaysFilter.hidden = false;
      }
      if (window.resourceScheduler) {
        this.handleChangeVisibleRole();
      }
    }
    await this.initProject();
    const isCollapsed = localStorage.getItem("PROJECT_CALENDAR_COLLAPSED");
    if (!!isCollapsed) {
      await window.projectScheduler.widgetMap.minimize.hide();
      await window.projectScheduler.widgetMap.expand.show();
    }
    this.makeWsConnection();
    // set watcher to track if ws connection was closed - recrete connection
    this.socketIntervalId = setInterval(() => {
      if (this.socket.readyState === 3) {
        this.socket.removeEventListener("message", this.websocketEventHandler);
        console.log("WS connection closed. Reconnecting...");
        this.makeWsConnection();
      }
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.socketIntervalId);
    document.body.classList.remove("md", "sm", "lg");
    this.dismissEventResolve();
    if (this.socket.close) {
      this.socket.close();
    }
    if (window.projectCalendarRef && !window.projectCalendarRef.closed) {
      window.projectCalendarRef.close();
    }
    document.removeEventListener("message", this.receiveMessage);
    document.removeEventListener("click", this.resourceLinkClickHandler);
    // document.removeEventListener("mouseover", this.handleLoadTooltip);
    if (window.projectScheduler) {
      window.projectScheduler.destroy();
    }
    if (window.resourceScheduler) {
      window.resourceScheduler.destroy();
    }
    window.checklistState = undefined;
    authImage.imagesStore.reset();
  },
  beforeRouteLeave(to, from, next) {
    if (this.$route.query.detachMode === "true") {
      next(false);
    } else {
      next();
    }
  },
  methods: {
    ...mapActions("montagsplanung", { initMontagsPlanung: "init" }),
    ...mapActions("projectRangeResolve", { resolveConflictEvent: "resolveEvent", dismissEventResolve: "dismiss" }),
    ...mapActions("deleteCalendarEvent", { initDeleteEventModal: "init" }),
    ...mapActions("granularitySettings", { updateGranularity: "updateSetting", setVisible: "setVisible" }),
    getFirstAvailableCalendar() {
      const specificAccess = this.calendarAccess.specificAccess;
      let calendarConfig, resourceType;
      if (specificAccess.employeeCalendarAccess) {
        calendarConfig = employeeConfig;
        resourceType = "employee";
      } else if (specificAccess.vehicleCalendarAccess) {
        calendarConfig = vehicleConfig;
        resourceType = "vehicle";
      } else if (specificAccess.machineCalendarAccess) {
        calendarConfig = machineConfig;
        resourceType = "machine";
      } else if (specificAccess.rhbCalendarAccess) {
        calendarConfig = rhbConfig;
        resourceType = "rhb";
      } else if (specificAccess.supplyCalendarAccess) {
        calendarConfig = supplyConfig;
        resourceType = "supply";
      } else if (specificAccess.subcontractorCalendarAccess) {
        calendarConfig = subcontractorConfig;
        resourceType = "subcontractor";
      }
      return { calendarConfig, resourceType };
    },
    makeWsConnection() {
      // initialize web-socket connection
      const wsOrigin = ORIGIN.replace("http", "ws");
      this.socket = new WebSocket(`${wsOrigin}/ws/events`);
      console.log("WS connection created.");
      this.socket.addEventListener("message", this.websocketEventHandler);
    },
    websocketEventHandler(event) {
      const eventData = JSON.parse(event.data);
      if (eventData.type === "PROJECT_UPDATED") {
        (async () => {
          try {
            await this.dataManager.fetchProjects();
            this.setupProjectSchedulerData("PROJECT_UPDATED");
            Message({ type: "success", message: "Projektkalender wurde aktualisiert" });
          } catch (error) {
            throw error;
          }
        })();
      } else {
        this.checkForUpdates({ ...eventData });
      }
    },
    async receiveMessage(e) {
      if (e.origin !== window.location.origin) {
        return;
      }
      switch (e.data.type) {
        case "COLLAPSE_PROJECT_CALENDAR":
          this.handleCollapseProjects();
          break;
        case "EXPAND_PROJECT_CALENDAR":
          this.handleExtendProjects();
          break;
        case "DETACH_PROJECT_CALENDAR":
          const features = "resizable=yes, scrollbars=yes, status=yes, width=1100";
          window.projectCalendarRef = window.open(
            `/app/project-calendar?date=${moment(this.dataManager.viewDateRange.startDate).format(
              "YYYY-MM-DD"
            )}&detachMode=true&kiosk=${this.isKioskMode}`,
            "Projekt Kalendar",
            features
          );
          // window.projectScheduler = null;
          window.calendarSplitterRef.destroy();
          window.projectScheduler.destroy();
          await this.setupResourceCalendar(this.activeCalendar);
          this.setupHolidayDateRanges(this.dataManager.viewDateRange);
          this.resourceDropdownVisible = false;
          break;
        case "DETACHED_CALENDAR_CLOSED":
          if (window.projectCalendarRef && !window.projectCalendarRef.closed) {
            window.projectCalendarRef.close();
            this.resourceDropdownVisible = true;
          }
          this.$router.go();
          break;
        case "PG1_SELECTED":
          this.handleSelectPg2(e.data.payload);
          break;
        case "PG1_DESELECTED":
          this.handleDiscardPg();
          break;
        case "UPDATE_VIEWRANGE":
          {
            const payload = e.data.payload;
            // sends events to corresponding calendars to apply new timerange in calendar
            if (this.projectMode && this.isSyncViewDate) {
              // need to distinguish between switching zoom and moving dateranges
              // because otherwise it causes endless loop of updates
              if (window.projectScheduler.viewPreset.data.id !== payload.viewPresetId) {
                window.projectScheduler.zoomToLevel(payload.viewPresetId, {
                  startDate: payload.startDate,
                  endDate: payload.endDate,
                });
                window.projectScheduler.widgetMap.zoomInButton.disabled = payload.viewPresetId === "week_1";
                window.projectScheduler.widgetMap.zoomOutButton.disabled = payload.viewPresetId === "week_4";
              } else {
                window.projectScheduler.setTimeSpan(payload.startDate, payload.endDate);
              }
            } else {
              if (window.resourceScheduler.viewPreset.data.id !== payload.viewPresetId) {
                window.resourceScheduler.zoomToLevel(payload.viewPresetId, {
                  startDate: payload.startDate,
                  endDate: payload.endDate,
                });
                window.resourceScheduler.widgetMap.zoomInButton.disabled = payload.viewPresetId === "week_1";
                window.resourceScheduler.widgetMap.zoomOutButton.disabled = payload.viewPresetId === "week_4";
              } else {
                window.resourceScheduler.setTimeSpan(payload.startDate, payload.endDate);
              }
            }
            // preserve changes in dataManager
            this.debounceSetDateRange(payload);
          }
          break;
        case "UNAVAILABLE_FILTER": {
          const hideUnavailable = e.data.payload;
          this.unavailableHidden = hideUnavailable;
          this.handleFilterUnavailable({ ...this.dataManager.viewDateRange });
          break;
        }
        case "SHOW_TEAMS": {
          const { value: showTeams, resourceType } = e.data.payload;
          this.handleShowTeams(showTeams, resourceType);
          if (showTeams) {
            localStorage.setItem(`SHOW_TEAMS_${resourceType}`, "true");
          } else {
            localStorage.removeItem(`SHOW_TEAMS_${resourceType}`);
          }
          break;
        }
        case "GRANULARITY_CHANGED": {
          // const payload = e.data.payload;
          // this.updateGranularity(payload);
          this.setVisible(true);
          window.resourceScheduler.allowOverlap = this.eventsCanOverlap;
          break;
        }
        case "CHECKLIST_ITEM_CHANGED": {
          const payload = e.data.payload;
          if (payload) {
            this.handleChangeCalendar(payload.resourceType);
            window.resourceScheduler.widgetMap["searchbar"].value = payload.name;
          } else if (window.resourceScheduler.widgetMap["searchbar"].value) {
            window.resourceScheduler.widgetMap["searchbar"].value = "";
          }
          break;
        }
        case "SHOW_RESOURCE_PICTURE": {
          this.$refs.picturePreview?.showPreview(e.data.payload);
          break;
        }
        case "HIDE_RESOURCE_PICTURE": {
          this.$refs.picturePreview?.hidePreview();
          break;
        }
        default:
          break;
      }
    },
    resourceLinkClickHandler(e) {
      const classList = e.target.classList;
      if (classList.contains("_resource-link")) {
        e.preventDefault();
        const path = e.target.getAttribute("href");
        if (!this.isKioskMode) {
          const resourceType = e.target.dataset.resourcetype;
          const hasViewAccessToResource = this.$can("read", resourceType);
          if (hasViewAccessToResource && path) {
            this.$router.push({ path: e.target.getAttribute("href") });
          }
        }
      } else if (classList.contains("_montagsplanung")) {
        e.preventDefault();
        const resourceId = e.target.getAttribute("data-resourceid");
        if (resourceId) {
          const resource = window.projectScheduler.resourceStore.getById(resourceId);
          this.initMontagsPlanung(resource);
        } else {
          console.warn("resourceId not found for element:", e.target);
        }
      } else if (classList.contains("_project-info")) {
        e.preventDefault();
        e.stopPropagation();
        const resourceId = e.target.getAttribute("data-resourceid");
        this.projectInfoId = resourceId;
      } else if (classList.contains("_add-event")) {
        e.preventDefault();
        e.stopPropagation();
        const resourceId = e.target.getAttribute("data-resourceid");
        const resourceRecord = window.resourceScheduler.resourceStore.getById(resourceId);
        this.openEditor({ source: window.resourceScheduler, resourceRecord: resourceRecord });
      } else if (classList.contains("_project-add")) {
        e.preventDefault();
        e.stopPropagation();
        this.openQuickProjectSetup();
      } else if (classList.contains("_hotel-action")) {
        // e.preventDefault();
        if (classList.contains("booked")) {
          const hotelInfoStr = e.target.dataset.hotelinfo || "{}";
          const hotelInfo = JSON.parse(decodeURIComponent(hotelInfoStr));

          if (hotelInfo.day && hotelInfo.projectId) {
            this.$refs.hotelInfo.showInfo(hotelInfo);
          } else {
            console.warn("Hotel Info is not complete, should have 'day' and 'projectId'", hotelInfo);
          }
        } else if (!this.isKioskMode && classList.contains("should-be-booked")) {
          const projectId = e.target.dataset.projectid;
          const bookingId = e.target.dataset.bookingid;
          const project = this.dataManager.getProjectById(projectId);
          this.$refs.hotelBookingDialog.initEditBooking({ project, bookingId });
        } else if (!this.isKioskMode && classList.contains("hotel-placeholder")) {
          const projectId = e.target.dataset.projectid;
          const date = e.target.dataset.date;
          const project = this.dataManager.getProjectById(projectId);
          this.$refs.hotelBookingDialog.initCreateBooking({ project, clickedDate: date });
        }
      } else if (classList.contains("_project-info-cell")) {
        const projectInfoStr = e.target.dataset.info || "{}";
        this.$refs.projectCellInfo.showInfo(projectInfoStr);
      } else if (classList.contains("_project-status")) {
        if (!this.isKioskMode && this.hasAccessToInvoices) {
          const projectId = e.target.dataset.projectid;
          this.$router.push({
            name: "Project View",
            params: { id: projectId, tab_pane: "invoices" },
          });
        }
      } else if (classList.contains("_event-info")) {
        const { eventid, resourcetype } = e.target.dataset;
        const eventRecord = this.dataManager.getDbEvent(resourcetype, eventid);
        if (!eventRecord) {
          throw new Error(`Event not found eventid: ${eventid} resourcetype: ${resourcetype}`);
        }
        this.eventCommentVisible = true;
        this.eventCommentData = {
          eventId: eventid,
          resourceType: resourcetype,
          comment: eventRecord.comment || null,
        };
      } else if (classList.contains("_copy-resources")) {
        this.copyResourcesVisible = true;
        const { projectid } = e.target.dataset;
        const projectMeta = this.dataManager.getProjectById(projectid);
        this.copyResourcesProject = {
          id: projectid,
          dateRange: projectMeta.dateRange.slice(),
          name: projectMeta.title,
        };
      } else if (!this.isKioskMode && classList.contains("_resource-thumbnail") && !classList.contains("placeholder")) {
        this.$refs.picturePreview.showDialog(e.target.parentNode.dataset.picture);
      }
    },
    handleChangeCalendar(command) {
      this.activeCalendar = command;
    },
    handleChangeVisibleRole(command) {
      if (command) {
        const newFilters = { ...this.employeeRoleFilters, [command]: !this.employeeRoleFilters[command] };
        this.employeeRoleFilters = newFilters;
      }
      const filters = Object.entries(this.employeeRoleFilters).reduce((arr, entry) => {
        if (entry[1]) {
          arr.push(entry[0]);
        }
        return arr;
      }, []);
      if (window.resourceScheduler) {
        window.resourceScheduler.resourceStore.filter({
          id: "employee_role_filter",
          filterBy: (resource) => {
            return filters.includes(resource.companyRole);
          },
        });
      }
      this.fitWidthForCalendarColumns();
    },
    handleResouceFilter(command) {
      if (command) {
        if (command === "all") {
          let booleanValue;
          if (Object.values(this.projectResourceFilters).every(Boolean)) {
            booleanValue = false;
          } else {
            booleanValue = true;
          }
          this.projectResourceFilters = Object.keys(this.projectResourceFilters).reduce((obj, key) => {
            obj[key] = booleanValue;
            return obj;
          }, {});
        } else {
          const newFilters = { ...this.projectResourceFilters, [command]: !this.projectResourceFilters[command] };
          this.projectResourceFilters = newFilters;
          localStorage.setItem(RESOURCE_FILTERS_NAMESPACE, JSON.stringify(newFilters));
        }
      }
      this.setupProjectSchedulerData();
    },
    prepareProjectResources() {
      const colorSettings = this.colorSettings;
      const viewDateRange = moment.range(
        this.dataManager.viewDateRange.startDate,
        this.dataManager.viewDateRange.endDate
      );
      const isExpandedByDefault = localStorage.getItem("PROJECT_CALENDAR_COLLAPSED") !== "true";

      const visibleResources = Object.values(this.dataManager.projects).reduce((array, project) => {
        const projectDateRange = moment.range(project.dateRange).snapTo("day");
        if (viewDateRange.overlaps(projectDateRange, { adjacent: false })) {
          const isExpanded =
            typeof this.expandedProjects[project.id] === "boolean"
              ? this.expandedProjects[project.id]
              : isExpandedByDefault;
          const normalizedEntity = {
            ...project,
            active: project.active,
            cls: project.isWorkshop ? "is-workshop" : "",
            expanded: isExpanded,
            children: project.children.reduce((arr, { visible, ...childProps }) => {
              // handle display of selected project resources
              if (!this.projectResourceFilters[childProps.resourceType]) {
                arr.push({
                  ...childProps,
                  isLeaf: true,
                  iconCls: this.resourceIconsMap[childProps.resourceType],
                  active: project.active,
                  eventColor: colorSettings[childProps.resourceType],
                  // cls: project.active ? "" : "unavailable"
                });
              }
              return arr;
            }, []),
          };
          array.push(normalizedEntity);
        }
        return array;
      }, []);
      return visibleResources;
    },
    makeProjectTimeRanges(preparedProjects) {
      const resourceRanges = preparedProjects.reduce((allRanges, project) => {
        const projectRanges = project.children.map((child) => ({
          resourceId: child.id,
          startDate: moment(project.dateRange[0]).startOf("day").toDate(),
          endDate: moment(project.dateRange[1]).endOf("day").toDate(),
          // timeRangeColor: "green"
          cls: project.active ? "project-range" : "unavailable",
        }));
        if (project.interruptions) {
          const projectInterruptionRanges = project.interruptions.reduce((ranges, item) => {
            const startDate = moment(item.dateRange[0]).startOf("day").toDate();
            const endDate = moment(item.dateRange[1]).endOf("day").toDate();
            const name = `Unterbrechung${item.notes ? `: ${item.notes}` : ""}`;
            ranges.push(
              {
                resourceId: project.id,
                startDate: startDate,
                endDate: endDate,
                cls: "project-interruption",
                name: name,
              },
              ...project.children.map((child) => ({
                resourceId: child.id,
                startDate: startDate,
                endDate: endDate,
                cls: "project-interruption resource",
                // name: name,
              }))
            );
            return ranges;
          }, []);
          allRanges = allRanges.concat(projectInterruptionRanges);
        }
        allRanges = allRanges.concat(projectRanges);
        return allRanges;
      }, []);
      return resourceRanges;
    },
    projectEventRenderer({ eventRecord, resourceRecord, renderData }) {
      // individual render of project metadata
      if (eventRecord.isMetaEvent) {
        let eventContent = "";
        if (eventRecord.projectStatusCell) {
          const tooltipText = eventRecord.projectStatusCell.tooltipText
            .map((invoiceStr) => `<div>${invoiceStr}</div>`)
            .join("");
          eventContent += `<div class="project-status-wrap"><div class="_project-status project-status-cell ${eventRecord.projectStatusCell.className}" data-projectid="${eventRecord.resourceId}" data-btip="${tooltipText}">
          ${eventRecord.projectStatusCell.name}
          </div></div>`;
        }
        if (eventRecord.departureStatusCell) {
          eventContent += `<div class="departure-cell" title="${eventRecord.departureStatusCell.time}">${eventRecord.departureStatusCell.time}</div>`;
        }
        eventContent += "<div class='d-flex'>";
        if (eventRecord.detailedInfoCell) {
          eventContent += `<div class="_project-info-cell detailed-info-cell" data-info="${
            eventRecord.detailedInfoCell.freeText
          }" data-btip="${eventRecord.detailedInfoCell.freeText ? "Click für Details" : ""}"></div>`;
        }
        if (eventRecord.hotelStatusCell) {
          let className = "hotel-cell";
          let hotelInfo;
          let bookingEventId;
          if (eventRecord.hotelStatusCell.isBooked) {
            className += " _hotel-action booked";
            hotelInfo = encodeURIComponent(JSON.stringify(eventRecord.hotelStatusCell.hotelInfo));
          } else if (eventRecord.hotelStatusCell.shouldBeBooked) {
            className += " _hotel-action should-be-booked";
            bookingEventId = eventRecord.hotelStatusCell.bookingEventId;
          } else {
            className += " _hotel-action hotel-placeholder";
          }
          eventContent += `<div class="${className}" data-projectid="${
            resourceRecord.id
          }" data-date="${eventRecord.startDate.toISOString()}" data-bookingid="${bookingEventId}" data-hotelinfo="${hotelInfo}" ${
            eventRecord.hotelStatusCell.isBooked ? 'data-btip="Click für Details"' : ""
          }></div>`;
          // eventContent += `<div class="${className}" data-hotelinfo="${hotelInfo}" data-btip="${tooltipText}"></div>`;
        } else {
          eventContent += `<div class="hotel-cell hotel-invisible"></div>`;
        }
        eventContent += "</div>";

        return StringHelper.decodeHtml(
          `<div class="project-status-cell-wrap ${eventRecord.projectStatusClass}">${eventContent}</div>`
        );
      } else {
        // Make events with low height use a small font (uses em in styling, will scale)
        renderData.cls.tiny = renderData.height < 23;
        let endMarker = "";
        renderData.cls.commentLayout = true;
        if (eventRecord.comment) {
          renderData.cls.withComment = true;
          endMarker += `<span class="b-end-marker info-tag _event-info" data-resourcetype="${eventRecord.resourceType}" data-eventid="${eventRecord.data.dbEventId}">INFO</span>`;
        } else if (!this.isNoEditAccess(eventRecord.resourceType)) {
          endMarker += `<i class="b-end-marker b-fa b-fa-plus-circle _event-info" data-resourcetype="${eventRecord.resourceType}" data-eventid="${eventRecord.data.dbEventId}"></i>`;
        }
        let quantity = "";
        if (eventRecord.quantity) {
          quantity = ` (${eventRecord.quantity}${eventRecord.unitName ? ` ${eventRecord.unitName}` : ""})`;
        }
        return StringHelper.encodeHtml(eventRecord.name + quantity) + endMarker;
      }
    },
    createParentRowEvents(projects) {
      return projects.reduce((array, project) => {
        if (!project.dateRange.length) {
          console.warn(`!!!${project.title} does not have a date range -> skip!!!`);
          return array;
        }
        // skip workshop projects
        if (project.isWorkshop) {
          return array;
        }
        // const isWorkingScheduleSetup = !!project.workingSchedule;
        // split working schedules by generic and dateranges to process further
        // basically if dateRange is set - it's dateranges schedule, otherwise - it's generic and applied
        // on whole project daterange except dateranged schedule. So dateranged overwrites generic
        // const { genericSchedule, daterangedSchedule } =
        //   isWorkingScheduleSetup &&
        //   project.workingSchedule.reduce(
        //     (obj, schedule) => {
        //       if (schedule.dateRange && schedule.dateRange.length === 2) {
        //         obj.daterangedSchedule.push({ ...schedule, dateRange: moment.range(schedule.dateRange).snapTo("day") });
        //       } else {
        //         obj.genericSchedule.push(schedule);
        //       }
        //       return obj;
        //     },
        //     { genericSchedule: [], daterangedSchedule: [] }
        //   );
        const events = this.makeWrapperEventsForProject(project);
        array.push(...events);
        return array;
      }, []);
    },
    makeWrapperEventsForProject(project) {
      const events = [];
      const projectDateRange = moment.range(project.dateRange).snapTo("day");
      const days = Array.from(projectDateRange.by("day"));
      const interruptionDateRanges = project.interruptions.map((i) =>
        moment.range(i.dateRange.map((d) => d.split("T")[0])).snapTo("day")
      );

      for (let index = 0; index < days.length; index++) {
        const day = days[index];
        const wrapperEvent = this.makeWrapperEvent(project, day, interruptionDateRanges);
        events.push(wrapperEvent);
      }
      return events;
    },
    makeWrapperEvent(project, day, interruptionDateRanges) {
      // create event to wrap cells
      // sys_ prefix is used for quick editing of the events
      const wrapperEvent = {
        id: `sys_${project.id}_${day.format("YYMMDD")}`,
        resourceMargin: 3,
        resourceId: project.id,
        draggable: false,
        resizable: false,
        startDate: day.clone().startOf("day").toDate(),
        endDate: day.clone().endOf("day").toDate(),
        cls: "project-meta",
        eventStyle: "hollow",
        color: "gray",
        locked: true,
        // info for rendering project metadata row event
        isMetaEvent: true,
        projectStatusClass: "",
        projectStatusCell: null,
        hotelStatusCell: null,
        departureStatusCell: null,
        detailedInfoCell: null,
      };
      //progress_1 = reserved -> project-cell-reserved
      //progress_2 = executing -> project-cell-executing
      //progress_3 = finished -> project-cell-finished
      switch (project.progress) {
        case "progress_1":
          wrapperEvent.projectStatusClass = "project-cell-reserved";
          break;
        case "progress_2":
          wrapperEvent.projectStatusClass = "project-cell-executing";
          break;
        case "progress_3":
          wrapperEvent.projectStatusClass = "project-cell-finished";
          break;
      }
      wrapperEvent.projectStatusCell = { className: "", name: "", tooltipText: [] };
      // if (day.isAfter(today, "day")) {
      //   wrapperEvent.projectStatusClass += " futureInvoice";
      // } else {
      /// add invoice status cell

      const isInterrupted = interruptionDateRanges.some((range) => day.within(range));
      wrapperEvent.projectStatusCell = this.getProjectStatusCell({
        date: day,
        progress: project.progress,
        invoiceHistory: project.invoiceHistory,
        hasAccessToInvoices: this.hasAccessToInvoices,
        isInterrupted: isInterrupted,
      });
      // }
      // const isWorkingDay = this.isWorkingDay(day, { genericSchedule, daterangedSchedule });
      // add hotel cell - if alarm is on and is a working day
      if (this.calendarAccess.specificAccess.hotelInfo && project.hotelReserveAlarm) {
        // if excludeLastDayFromBooking is enabled - don't show red hotel booking cell
        // const excludeLastDayFromBooking = project.excludeLastDayFromBooking ? index === lastDayIdx : false;
        wrapperEvent.hotelStatusCell = this.getHotelCell({
          date: day,
          hotelBookingsEvents: project.bookings,
          // isWorkingDay: excludeLastDayFromBooking ? false : isWorkingDay,
          selectedHotels: project.selectedHotels,
          projectId: project.id,
        });
      }
      if (
        // isWorkingScheduleSetup && isWorkingDay &&
        this.calendarAccess.specificAccess.departureTimes &&
        project.departures
      ) {
        // add departure cell
        const departureCell = this.getDepartureCell({ date: day, departures: project.departures });
        // if (project.departures) {
        //   console.log("project.title", project.departures, departureCell);
        // }
        if (departureCell.isBooked) {
          wrapperEvent.departureStatusCell = departureCell;
        }
      }
      const detailedInfoCell = this.getDetailedInfoCell({ date: day, detailedInfo: project.detailedInfoSchedule });
      if (detailedInfoCell.freeText) {
        wrapperEvent.detailedInfoCell = detailedInfoCell;
      }
      // end
      return wrapperEvent;
    },
    getProjectStatusCell({ date, progress, invoiceHistory, hasAccessToInvoices, isInterrupted }) {
      const today = new Date();
      const projectStatusCell = {
        className: "",
        name: "",
        tooltipText: [],
      };
      if (!this.calendarAccess.specificAccess.invoices) {
        return projectStatusCell;
      }
      if (progress === "progress_1") {
        return projectStatusCell;
      }
      if (invoiceHistory.length) {
        const invoicesWithinDay = invoiceHistory.filter((invoice) => {
          const invoicePeriod =
            invoice.dateRange && invoice.dateRange.length
              ? moment.range(
                  moment(invoice.dateRange[0], "DD.MM.YYYY"),
                  moment(invoice.dateRange[1], "DD.MM.YYYY").endOf("day")
                )
              : null;
          return invoicePeriod && invoicePeriod.contains(date);
        });
        let invoiceClassName;

        invoicesWithinDay.forEach((invoice) => {
          const eventName = invoice.id ? `${invoice.shortname} #${invoice.id}` : invoice.shortname;
          const eventTooltip = [eventName, invoice.invoiceNumber, invoice.dateRange && invoice.dateRange.join("-")]
            .filter(Boolean)
            .join(" | ");
          projectStatusCell.tooltipText.push(eventTooltip);
          if (projectStatusCell.name) {
            projectStatusCell.name += `/${eventName}`;
          } else {
            projectStatusCell.name = eventName;
          }
          if (invoice.status === "PREPARATION" && !isInterrupted) {
            invoiceClassName = "noInvoice";
          } else if (invoice.status === "ACTIVE") {
            invoiceClassName = "acknowledged";
          } else {
            // only "INVOICED" is left
            if (invoice.shortname === "AR") {
              invoiceClassName = "darkblue";
            } else if (invoice.shortname !== "GS") {
              //GS invoice color should inherit from previous invoice type. that's why classname assignment is excluded
              invoiceClassName = "dark";
            }
          }
        });
        // AR should inherit other invoices color, but should also have own darkblue color if is single invoice at day
        if (
          invoiceClassName &&
          (invoicesWithinDay.length === 1 ||
            invoicesWithinDay.some((invoice) => invoice.shortname !== "AR") ||
            !projectStatusCell.className)
        ) {
          projectStatusCell.className += invoiceClassName;
        }
      }
      if (!projectStatusCell.className && !isInterrupted && date.isBefore(today, "day")) {
        projectStatusCell.className = "noInvoice";
      }
      if (hasAccessToInvoices) {
        projectStatusCell.className += " pointer";
      }
      return projectStatusCell;
    },
    getHotelCell({ date, hotelBookingsEvents = [], selectedHotels, projectId }) {
      // init hotel event as not reserved. change it if hotel reservation exists.
      const hotelCell = {
        hotelInfo: {},
        isBooked: false,
        shouldBeBooked: false,
        bookingEventId: null,
      };
      for (let index = 0; index < selectedHotels.length; index++) {
        const hotel = selectedHotels[index];
        const reservedRange = moment.range(hotel.hotelDateRange).snapTo("day");
        // hooray! days is within hotel reservation date range
        if (reservedRange.contains(date)) {
          hotelCell.isBooked = true;
          hotelCell.hotelInfo = {
            day: moment(date).format("YYYY-MM-DD"),
            projectId: projectId,
          };
          break;
        }
      }
      // if hotel is not booked but should be booked (according to the booking events) - show red cell
      if (!hotelCell.isBooked) {
        const bookingEvent = hotelBookingsEvents.find(({ start, end }) => {
          return date.isSameOrAfter(start, "day") && date.isSameOrBefore(end, "day");
        });
        hotelCell.bookingEventId = bookingEvent ? bookingEvent._id : null;
        hotelCell.shouldBeBooked = !!bookingEvent;
      }
      return hotelCell;
    },
    getDepartureCell({ date, departures }) {
      const departureCell = {
        isBooked: false,
        time: null,
      };
      for (let index = 0, len = departures.length; index < len; index++) {
        const departure = departures[index];
        //format date and time string to work with it
        if (moment.range(departure.dateRange).contains(date)) {
          departureCell.time = departure.time;
          departureCell.isBooked = true;
          break;
        }
      }
      return departureCell;
    },
    getDetailedInfoCell({ date, detailedInfo }) {
      const detailedInfoCell = {
        freeText: "",
      };
      if (detailedInfo) {
        for (let index = 0, len = detailedInfo.length; index < len; index++) {
          const detaledInfo = detailedInfo[index];
          //format date and time string to work with it
          if (moment.range(detaledInfo.dateRange).snapTo("day").contains(date)) {
            detailedInfoCell.freeText = detaledInfo.freeText;
            break;
          }
        }
      }
      return detailedInfoCell;
    },
    // calculates if date is a working day according to project working days schedule
    isWorkingDay(date, { genericSchedule = [], daterangedSchedule = [] }) {
      // if no workingSchedule - no working days by default
      if (genericSchedule.length || daterangedSchedule.length) {
        const currentWeekDay = date.isoWeekday();
        // first check whether some dateranged schedule matches current day - dateranged schedule has higher priority
        // NOTE: schedule.dateRange should be set to moment.range at previous step
        const relatedDaterangedSchedule = daterangedSchedule.find((schedule) => date.within(schedule.dateRange));
        if (relatedDaterangedSchedule) {
          // current date falls on dateranged schedule - check if weekday is within working schedule
          return relatedDaterangedSchedule.weekDays.includes(currentWeekDay);
        } else {
          // check if current date falls on generic schedule - check if weekday is within working schedule
          return genericSchedule.some((schedule) => schedule.weekDays.includes(currentWeekDay));
        }
      } else {
        return false;
      }
    },
    async handleEventDropFinalize({ source, context }) {
      context.async = true;
      // prevent unchanged data
      if (
        context.newResource.id === context.resourceRecord.id &&
        moment(context.startDate).isSame(context.eventRecords[0].data.startDate, "day")
      ) {
        context.finalize(false);
        return;
      }
      if (context.resourceRecord.data.resourceType === "supply") {
        const resourceEvents = context.newResource.events.filter((event) => !!event.originalData.statusEvent);
        const momentDate = moment(context.startDate).add(4, "hours");
        const dateRanges = resourceEvents.map((event) => moment.range(event.startDate, event.endDate).snapTo("day"));
        if (dateRanges.some((range) => momentDate.within(range))) {
          Message.error("Verbrauchsmaterial ist nicht verfügbar.");
          context.finalize(false);
          return;
        }
      }
      if (context.newResource.data.active === false || context.resourceRecord.data.active === false) {
        context.finalize(false);
        Message({
          message: "Projekt oder Ressource ist inaktiv",
          type: "warning",
        });
        return;
      }
      let finalizeResult = true;
      if (context.newResource.data.resourceType !== context.resourceRecord.data.resourceType) {
        Message({
          message: `${resourceLabelMap[context.newResource.data.resourceType]}  kann nicht ${
            resourceLabelMap[context.resourceRecord.data.resourceType]
          } zugeordnet werden`,
          type: "warning",
        });
        finalizeResult = false;
      }
      let isStatusEvent = !!context.eventRecords[0].statusEvent;

      // check if event falls within project date range
      if (
        !isStatusEvent &&
        source.ref === "projectScheduler" &&
        finalizeResult &&
        !context.newResource.parent.isWorkshop
      ) {
        const newProjectDateRange = moment.range(context.newResource.parent.data.dateRange).snapTo("day");
        if (
          !moment(context.startDate).within(newProjectDateRange) ||
          !moment(context.endDate).within(newProjectDateRange)
        ) {
          Message({
            message: "Sie müssen innerhalb des Projektes bleiben.",
            type: "warning",
          });
          finalizeResult = false;
        }
      }
      // send network request only if all checks passed!
      if (finalizeResult) {
        const eventRecord = context.eventRecords[0];
        try {
          console.log("context", context);
          console.log("eventRecord", eventRecord);
          console.log("this.dataManager.events", this.dataManager.events);
          const dbEventId = eventRecord.dbEventId || eventRecord.data.id;
          const resourceType = eventRecord.resourceType || eventRecord.originalData.resourceType;
          const dbEvent = this.dataManager.events[resourceType].find((event) => event.id === dbEventId);
          let dbEventEndDate = moment(dbEvent.end);
          const endDateDiff = moment(context.startDate).diff(moment(dbEvent.start), "days");
          dbEventEndDate.add(endDateDiff, "days");
          const formData = {
            start: moment(context.startDate).format("YYYY-MM-DD"),
            end: moment(context.endDate).format("YYYY-MM-DD"),
            resourceType: dbEvent.resourceType,
          };
          // considering that only project calendar will have tree mode and as result "parent" property
          if (!isStatusEvent && context.newResource.parent) {
            formData.projectId = context.newResource.parent.id;
            // otherwise resourceId was changed
          } else {
            formData.resourceId = context.newResource.id;
          }
          let response;
          if (isStatusEvent) {
            response = await this.axios.put(`/api/status-events/${dbEventId}`, formData);
          } else {
            response = await this.axios.put(`/api/project-events/${dbEventId}`, formData, {
              params: { mode: getGranularityMode(formData.resourceType) },
            });
          }
          const { startDate, endDate } = this.dataManager.viewDateRange;

          this.dataManager.fetchEvents(this.activeCalendar, startDate, endDate, true);
          this.ignoreNextUpdateCheck();
          if (source.ref === "resourceScheduler" && !window.projectScheduler.isDestroyed) {
            const projectEventRecord = window.projectScheduler.eventStore.findRecord(
              "dbEventId",
              context.eventRecord.dbEventId
            );
            if (projectEventRecord) {
              projectEventRecord.set({
                name: response.data.title,
                title: response.data.title,
                resourceId: `${response.data.projectId}_${response.data.resourceType}`,
                dbEventId: response.data.id,
                dbResourceId: response.data.resourceId,
                start: moment(response.data.start).startOf("day").toDate(),
                end: moment(response.data.end).endOf("day").toDate(),
                startDate: moment(response.data.start).startOf("day").toDate(),
                endDate: moment(response.data.end).endOf("day").toDate(),
              });
              window.projectScheduler.refreshRows();
            }
            // avoid applying resize on employee events because of dummy event grouping by 3 in column
          } else if (window.resourceScheduler) {
            const relatedRecord = window.resourceScheduler.eventStore.findRecord(
              "dbEventId",
              context.eventRecord.dbEventId
            );
            if (relatedRecord) {
              const project = this.dataManager.getProjectById(response.data.projectId);
              relatedRecord.set({
                name: project.title,
                title: project.title,
                resourceId: response.data.resourceId,
                dbEventId: response.data.id,
                dbResourceId: response.data.resourceId,
                start: moment(response.data.start).startOf("day").toDate(),
                end: moment(response.data.end).endOf("day").toDate(),
                startDate: moment(response.data.start).startOf("day").toDate(),
                endDate: moment(response.data.end).endOf("day").toDate(),
              });
              window.resourceScheduler.refreshRows();
            }
          }
          finalizeResult = true;
          window.projectScheduler.refreshRows();
        } catch (error) {
          console.error("error", error);
          finalizeResult = false;
          if (error.response && error.response.data && error.response.data.message) {
            Message.error(error.response.data.message);
          }
          // don't throw an error here in because we need to call finalize!
        }
      }
      console.log("finalizeResult", finalizeResult);
      context.finalize(finalizeResult);
      // this.setupProjectSchedulerData();
    },
    validateEventDrag({ startDate, endDate, newResource, resourceRecord, eventRecords }) {
      const event = eventRecords[0];
      let isValid = true;
      let message = "";
      // "parent" property is present in project calendar
      if (newResource.parent ? !newResource.parent.data.active : !newResource.data.active) {
        isValid = false;
        message = "Eine der Ressourcen ist inaktiv";
      }
      if (this.isNoEditAccess(event && event.originalData.resourceType)) {
        isValid = false;
        message = "kein Zugriff auf die Ressource";
      }
      // this check is sufficient for project calendar
      if (isValid && newResource.data.resourceType !== resourceRecord.data.resourceType) {
        message = "Bitte wieder auf korrekte Zeile ziehen!";
        isValid = false;
      }
      if (!event.data.statusEvent) {
        // check if event is within project date range
        let projectDateRange;
        if (newResource.parent && newResource.parent.data.dateRange) {
          // this part is for project calendar
          projectDateRange = moment.range(newResource.parent.data.dateRange).snapTo("day");
        } else if (event.data.projectId) {
          // this part is for resource calendar
          const project = this.dataManager.projects[event.data.projectId];
          if (project) {
            projectDateRange = moment.range(project.dateRange).snapTo("day");
          } else {
            throw new Error(`projectId not found: ${event.data.projectId}`);
          }
        } else {
          console.log("event", event);
          console.log("newResource", newResource);
          throw new Error("Unable to validate event drag. Project date range can not be found");
        }
        if ((isValid && !moment(startDate).within(projectDateRange)) || !moment(endDate).within(projectDateRange)) {
          isValid = false;
          message = "Sie müssen innerhalb des Projektes bleiben.";
        }
      }
      console.log("isValid", isValid);
      return {
        valid: isValid,
        message: message,
      };
    },
    validateEventResize({ eventRecord, startDate, endDate, resourceRecord }) {
      let isValid = true;
      let message = "";
      if (eventRecord.statusEvent) {
        return { isValid, message };
      }
      const isProjectCalendar = !!resourceRecord.parent;
      if (isProjectCalendar && !resourceRecord.parent.data.active) {
        isValid = false;
        message = "Projekt is inaktiv";
      }
      if (this.isNoEditAccess(eventRecord.originalData.resourceType)) {
        isValid = false;
        message = "kein Zugriff auf die Ressource";
      }
      // endDate equals first second of next day -> change it to last second of resized day
      endDate = moment(endDate)
        .subtract(4, "hours") // to make sure end date is correct
        .endOf("day")
        .toDate();
      let dateRange;
      if (isProjectCalendar) {
        dateRange = resourceRecord.parent.data.dateRange;
      } else {
        const projectId = eventRecord.data.projectId;
        const project = this.dataManager.projects[projectId];
        if (project) {
          dateRange = project.dateRange;
        }
      }
      const projectDateRange = moment.range(dateRange).snapTo("day");
      if ((isValid && !moment(startDate).within(projectDateRange)) || !moment(endDate).within(projectDateRange)) {
        isValid = false;
        message = "Element is außerhalb des Projektzeitraums";
      }
      return {
        valid: isValid,
        message: message,
      };
    },
    beforeEventEditHandler(data) {
      // allow only editing only of project events
      if (!data.eventRecord.data.locked) {
        if (data.eventRecord.dbEventId && !data.eventRecord.data.statusEvent) {
          this.openEditor(data, true);
        } else {
          this.openEditor(data, true);
        }
      }
      return false;
    },
    beforeEventAddHandler({ source, resourceRecords, eventRecord }) {
      this.openEditor({ source: source, resourceRecord: resourceRecords[0], eventRecord });
      return false;
    },
    openEditor({ source, resourceRecord, eventRecord }, isEditEvent) {
      if (!isEditEvent) {
        Object.assign(this, {
          projectEventCreate: {
            resourceRecord: resourceRecord,
            resourceId: resourceRecord.data.id,
            resourceType: resourceRecord.data.resourceType,
            eventRecord: eventRecord,
            projectId: eventRecord && eventRecord.projectId,
            visible: true,
          },
        });
        return;
      }
      if (eventRecord) {
        // edit status event
        if (eventRecord.statusEvent) {
          if (!eventRecord.readOnlyAccess) {
            Object.assign(this, {
              statusEventEdit: {
                resourceRecord: resourceRecord,
                resourceId: resourceRecord.data.id,
                resourceType: resourceRecord.data.resourceType,
                eventRecord: eventRecord,
                visible: true,
              },
            });
          }
        } else if (isEditEvent && source.ref === "resourceScheduler") {
          // edit project event
          Object.assign(this, {
            projectEventEdit: {
              resourceRecord: resourceRecord,
              resourceId: resourceRecord.data.id,
              resourceType: resourceRecord.data.resourceType,
              eventRecord: eventRecord,
              visible: true,
            },
          });
        }
      } else {
        if (source.ref === "projectScheduler") {
          return;
        }
        // [projectId, resourceType] or [resourceId]
        const [first, second] = resourceRecord.id.split("_");
        let projectId, resourceType, resourceId;
        // if open event editor in project calendar - resourceId will be like "{projectId}_{resourceType}"
        // otherwise if open event editor in resource calendar - resourceId == id of resource.

        // If opened event edit in Resource calendar
        if (!second) {
          resourceId = first;
          resourceType = resourceRecord.resourceType;
          // projectId - will be picked in modal window
        } else {
          // otherwise - Project calendar
          projectId = first;
          resourceType = second;
          resourceId = eventRecord.resourceId;
          // resourceId - will be picked in modal window
        }
        Object.assign(this, {
          projectEventCreate: {
            resourceRecord: resourceRecord,
            resourceId: resourceId,
            resourceType: resourceType,
            eventRecord: eventRecord,
            projectId: projectId,
            visible: true,
          },
        });
      }
    },
    onCloseProjectEventCreate() {
      Object.assign(this, {
        projectEventCreate: {
          resourceRecord: null,
          resourceId: null,
          resourceType: null,
          eventRecord: null,
          projectId: null,
          visible: false,
        },
      });
      if (window.projectScheduler && window.projectScheduler.refresh) {
        window.projectScheduler.refresh();
      }
      if (window.resourceScheduler && window.resourceScheduler.refresh) {
        window.resourceScheduler.refresh();
      }
    },
    onCloseEventEditor() {
      Object.assign(this, {
        statusEventEdit: {
          resourceRecord: null,
          resourceId: null,
          resourceType: null,
          eventRecord: null,
          visible: false,
          dayClicked: null,
        },
        projectEventEdit: {
          resourceRecord: null,
          resourceId: null,
          resourceType: null,
          eventRecord: null,
          visible: false,
        },
      });
      if (window.projectScheduler && window.projectScheduler.refresh) {
        window.projectScheduler.refresh();
      }
      if (window.resourceScheduler && window.resourceScheduler.refresh) {
        window.resourceScheduler.refresh();
      }
    },
    getResourceIdItems(resourceType) {
      if (this.dataManager.resources[resourceType]) {
        return this.dataManager.resources[resourceType].map((item) => ({
          text: item.title,
          resourceType: item.resourceType,
          displayName: item.displayName,
          value: item.id,
        }));
      } else {
        return [];
      }
    },
    handleCopyEvent(eventRecord) {
      this.$root.$emit("copyEvent", eventRecord);
    },
    handleEditAmount(eventRecord, resourceRecord) {
      this.showQuantityModal({ ...eventRecord }, resourceRecord);
    },
    async saveQuantity(eventId, newQuantity) {
      try {
        await this.dataManager.editQuantity(eventId, newQuantity);
      } catch (error) {
        throw error;
      }
    },
    showQuantityModal(eventRecord, resourceRecord) {
      this.isQuantityModalVisible = true;
      this.editingEventRecord = { ...eventRecord };
      this.editingResourceRecord = resourceRecord;
    },
    onQuantityModalClose() {
      this.isQuantityModalVisible = false;
      this.editingEventRecord = null;
      this.editingResourceRecord = null;
    },
    async handleDeleteEvent(eventRecord) {
      console.group("handleDeleteEvent");
      // const reduceFn = (arr, curr) => (curr.dbEventId === eventRecord.dbEventId ? arr.concat(curr.id) : arr);
      const projectCalResourceId = `${eventRecord.projectId}_${eventRecord.resourceType}`;
      if (!eventRecord.originalData.projectId) {
        await this.axios.delete(
          `/api/status-events/${eventRecord.originalData.dbEventId || eventRecord.originalData.id}`
        );
      } else {
        console.log("eventRecord", eventRecord);
        console.log("Deleting event");
        await this.axios.delete(
          `/api/project-events/${eventRecord.originalData.dbEventId || eventRecord.originalData.id}`
        );
        console.log("Event deleted");
        if (window.projectScheduler && !window.projectScheduler.isDestroyed) {
          console.log("Deleting event in project calendar");
          this.resolveConflictEvent(eventRecord.originalData.dbEventId);
          const connectedProjectEvents = window.projectScheduler.eventStore.reduce((arr, curr) => {
            console.log(curr.resourceId, projectCalResourceId);
            return curr.resourceId === projectCalResourceId && curr.dbEventId === eventRecord.dbEventId
              ? arr.concat(curr.id)
              : arr;
          });
          console.log("connectedProjectEvents", connectedProjectEvents);
          window.projectScheduler.eventStore.remove(connectedProjectEvents);
        }
      }
      if (window.resourceScheduler) {
        console.log("Deleting event in resource calendar");
        const connectedResourceEvents = window.resourceScheduler.eventStore.reduce(
          (arr, curr) => (curr.dbEventId === eventRecord.dbEventId ? arr.concat(curr.id) : arr),
          []
        );
        console.log("connectedResourceEvents", connectedResourceEvents);
        window.resourceScheduler.eventStore.remove(connectedResourceEvents);
      }
      this.preventNextCheck = true;
      console.groupEnd();
    },
    /**
     * eventId - event id to delete
     * newEvents - (optional) array of events to create instead of deleted one
     */
    async deleteEvents(eventIds, completeRemainingProject, newEvents) {
      try {
        if (completeRemainingProject && eventIds && eventIds.length) {
          await this.axios.delete(`/api/project-events/${eventIds[0]}`, { params: { removeAllNextEvents: true } });
        } else {
          await Promise.all(
            eventIds.map((eventId) => {
              return this.axios.delete(`/api/project-events/${eventId}`);
            })
          );
        }
        // eventIds.forEach(eventId => this.calendarManager.eventClicked(eventId));
        if (newEvents && newEvents.length) {
          await this.dataManager.addProjectEvents(newEvents);
        }
        if (window.resourceScheduler) {
          window.resourceScheduler.eventStore.remove(eventIds);
        }
        eventIds.forEach((eventId) => {
          this.resolveConflictEvent(eventId);
        });
      } catch (error) {
        Message(error.message);
        throw error;
      }
    },
    async resizeEvent({ source, context }) {
      context.async = true;
      context.endDate = moment(context.endDate).subtract(4, "hours").endOf("day").toDate();
      const resizeStart = moment(context.startDate).format("YYYY-MM-DD");
      const resizeEnd = moment(context.endDate).format("YYYY-MM-DD");
      try {
        const eventRecord = context.eventRecord;
        const dbEvent = this.dataManager.getDbEvent(
          eventRecord.originalData.resourceType,
          eventRecord.originalData.dbEventId || eventRecord.originalData.id
        );
        let newStart = dbEvent.start,
          newEnd = dbEvent.end;
        // start date was changed
        if (!moment(newStart).isSame(resizeStart, "day")) {
          newStart = resizeStart;
          // end date was changed
        } else if (!moment(newEnd).isSame(resizeEnd, "day")) {
          newEnd = resizeEnd;
        }
        const eventId = dbEvent.id;
        // if eventRecord is status event - use status event url, otherwise use project event url
        const url = context.eventRecord.data.statusEvent
          ? `/api/status-events/${eventId}`
          : `/api/project-events/${eventId}`;
        const formData = {
          start: newStart,
          end: newEnd,
        };
        const response = await this.axios.put(url, formData, {
          params: { mode: getGranularityMode(dbEvent.resourceType) },
        });
        const { startDate, endDate } = this.dataManager.viewDateRange;
        this.dataManager.fetchEvents(this.activeCalendar, startDate, endDate, true);
        this.resolveConflictEvent(eventId);
        if (source.ref === "resourceScheduler" && !window.projectScheduler.isDestroyed) {
          const projectEventRecord = window.projectScheduler.eventStore.findRecord(
            "dbEventId",
            context.eventRecord.dbEventId
          );
          if (projectEventRecord) {
            projectEventRecord.beginBatch();
            projectEventRecord.setStartDate(moment(response.data.start).startOf("day").toDate(), false);
            projectEventRecord.setEndDate(moment(response.data.end).endOf("day").toDate(), false);
            projectEventRecord.endBatch(true);
            window.projectScheduler.refreshRows();
          }
          // avoid applying resize on employee events because of dummy event grouping by 3 in column
        } else if (window.resourceScheduler) {
          const relatedRecord = window.resourceScheduler.eventStore.findRecord(
            "dbEventId",
            context.eventRecord.dbEventId
          );
          if (relatedRecord) {
            relatedRecord.beginBatch();
            relatedRecord.setStartDate(moment(response.data.start).startOf("day").toDate(), false);
            relatedRecord.setEndDate(moment(response.data.end).endOf("day").toDate(), false);
            relatedRecord.endBatch(true);
            window.resourceScheduler.refreshRows();
          }
        }

        this.preventNextCheck = true;
        context.finalize(true);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          if (error.response.data.code === 101) {
            Message({
              message: "Ressource kann einem Projekt nicht doppelt zugewiesen werden",
              type: "warning",
            });
          } else if (error.response.data.code === 10) {
            Message({
              message: "Sie kollidieren mit einem Status oder Projekt",
              type: "warning",
            });
          } else {
            Message({
              type: "warning",
              message: "Sie haben hierfür keine Berechtigung",
            });
          }
        }
        context.finalize(false);
        throw error;
      }
    },
    handleEditSatusEvent({ date, resourceRecord }) {
      if (this.isDisabledStatusEvents(resourceRecord.data.resourceType)) {
        return;
      }
      if (date) {
        Object.assign(this, {
          statusEventEdit: {
            resourceRecord: resourceRecord,
            resourceId: resourceRecord.data.id,
            resourceType: resourceRecord.data.resourceType,
            visible: true,
            dayClicked: date,
          },
        });
      }
    },
    /**
     * To handle timeaxis change between calendars in separate windows we use
     * "message" event that notifies corresponding windows to update calendar view
     */
    _handleTimeAxisChange({ config }) {
      this.unavailableHidden = false;
      this.debounceSetDateRange(config);
      if (this.projectMode && this.isSyncViewDate) {
        const presetId = window.projectScheduler.viewPreset.data.id;
        window.opener.postMessage(
          {
            type: "UPDATE_VIEWRANGE",
            payload: {
              startDate: config.startDate,
              endDate: config.endDate,
              viewPresetId: presetId,
            },
          },
          window.location.origin
        );
      } else if (window.projectCalendarRef) {
        const presetId = window.resourceScheduler.viewPreset.data.id;
        window.projectCalendarRef.postMessage(
          {
            type: "UPDATE_VIEWRANGE",
            payload: {
              startDate: config.startDate,
              endDate: config.endDate,
              viewPresetId: presetId,
            },
          },
          window.location.origin
        );
      }

      // this.handleFilterUnavailable(config);
      this.setupHolidayDateRanges(config);
      if (window.resourceScheduler && window.resourceScheduler.resourceStore.filters.get("available_days_filter")) {
        this.dataManager.updateBusyDayFilter();
      }
    },
    handleFilterUnavailable({ startDate, endDate }) {
      console.log("this.unavailableHidden", this.unavailableHidden);
      if (window.resourceScheduler && this.unavailableHidden) {
        const viewEndDate = moment(endDate).startOf("day"),
          viewStartDate = moment(startDate).endOf("day");
        const viewDateRange = moment.range(viewStartDate, viewEndDate);

        window.resourceScheduler.widgetMap.unavailableToggle.checked = this.unavailableHidden;
        window.resourceScheduler.resourceStore.filter({
          id: "unavailable",
          filterBy: (resource) => {
            if (resource.dateHistory && resource.dateHistory.length) {
              const isAvailable = resource.dateHistory.some(({ dateOfEntering, dateOfLeaving }) => {
                if (dateOfEntering && dateOfLeaving) {
                  return moment
                    .range(moment(dateOfEntering).startOf("day"), moment(dateOfLeaving).endOf("day"))
                    .snapTo("day")
                    .overlaps(viewDateRange);
                } else {
                  // otherwise - only dateOfEntering should be available
                  return (
                    viewStartDate.isSameOrAfter(moment(dateOfEntering).startOf("day"), "day") ||
                    moment(dateOfLeaving).endOf("day").within(viewDateRange)
                  );
                }
              });
              return isAvailable;
            } else {
              return true;
            }
          },
        });
      } else if (window.resourceScheduler) {
        // window.resourceScheduler.widgetMap.unavailableToggle.checked = this.unavailableHidden;
        window.resourceScheduler.resourceStore.removeFilter("unavailable");
      }
    },
    makeSchedulerConfig(config, namespace, resourceType) {
      const id = resourceType ? "resource-scheduler" : "";
      const self = this;
      const overlapRule =
        namespace === "projectScheduler" ? true : this.granularitySettings[resourceType] === "CONCURRENT";
      const extendedConfig = {
        id,
        ...config,
        allowOverlap: overlapRule,
        ref: namespace,
        listeners: {
          ...(config ? config.listeners : {}),
          thisObj: this,
          // to preserve selected date range
          timeAxisChange: this.handleTimeAxisChange,
          filter_busy_resources: (e) => this.dataManager.filterUpperCalendarResources(e.days),
          beforeEventEdit: this.beforeEventEditHandler,
          beforeEventAdd: this.beforeEventAddHandler,
          beforeEventResizeFinalize: this.resizeEvent,
          // eventDrop: this.handleEventDrop,
          beforeEventDropFinalize: this.handleEventDropFinalize,
        },
        appendTo: "scheduler-container",
        startDate: this.dataManager.viewDateRange.startDate,
        endDate: this.dataManager.viewDateRange.endDate,
      };

      if (!extendedConfig.features.eventDrag) {
        extendedConfig.features.eventDrag = {};
      }
      if (!extendedConfig.features.eventResize) {
        extendedConfig.features.eventResize = {};
      }
      // provide event drag validate functions
      extendedConfig.features.eventDrag.validatorFn = this.validateEventDrag;
      extendedConfig.features.eventDrag.validatorFnThisObj = this;
      // provide event resize validate functions
      extendedConfig.features.eventResize.validatorFn = this.validateEventResize;
      extendedConfig.features.eventResize.validatorFnThisObj = this;

      if (!this.projectMode) {
        if (namespace === "projectScheduler") {
          delete extendedConfig.listeners.timeAxisChange;
          // disable ablility to create events through project calendar
          extendedConfig.features.scheduleMenu = false;
        } else {
          extendedConfig.features.scheduleMenu = {
            items: {
              addStatusEvent: {
                icon: "b-fa b-fa-fw b-fa-plus",
                text: "Status hinzufügen",
                onItem: self.handleEditSatusEvent,
                disabled: self.isDisabledStatusEvents(resourceType),
              },
            },
          };

          // because resource calendar appears always on top
          extendedConfig.insertFirst = extendedConfig.appendTo;
          delete extendedConfig.appendTo;
        }
      }
      if (!extendedConfig.features.scheduleMenu && namespace !== "projectScheduler") {
        extendedConfig.features.scheduleMenu = {};
      }
      if (resourceType) {
        if (this.isNoEditAccess(resourceType)) {
          extendedConfig.features.eventDrag.disabled = true;
          extendedConfig.features.eventDrag.disabled = true;
          extendedConfig.features.eventResize.disabled = true;
          if (extendedConfig.features.eventMenu) {
            extendedConfig.features.eventMenu.disabled = true;
          }
          if (extendedConfig.features.scheduleMenu) {
            extendedConfig.features.scheduleMenu.disabled = true;
          }
          extendedConfig.createEventOnDblClick = false;
        } else {
          extendedConfig.features.eventDrag.disabled = false;
          extendedConfig.features.eventResize.disabled = false;
          if (extendedConfig.features.eventMenu.disabled) {
            extendedConfig.features.eventMenu.disabled = false;
          }
          extendedConfig.features.scheduleMenu.disabled = false;
          extendedConfig.createEventOnDblClick = true;
        }
      }

      if (extendedConfig.features.eventMenu) {
        extendedConfig.features.eventMenu.processItems = ({ items, eventRecord, resourceRecord }) => {
          if (eventRecord.originalData.statusEvent) {
            items.customCopyEvent = {
              text: "Dupliziere auf",
              icon: "b-fa b-fa-fw b-fa-copy",
              onItem: () => self.handleCopyEvent(eventRecord.originalData),
            };
          }
          // Edit units only for project events
          if (eventRecord.originalData.resourceType === "supply" && eventRecord.originalData.projectId) {
            items.supplyEditEvent = {
              text: this.$t("Edit units"),
              icon: "b-fa b-fa-fw b-fa-box",
              onItem: () => self.handleEditAmount(eventRecord.data, resourceRecord),
            };
          }
          // Prevent menu for "locked" event
          if (eventRecord.locked || resourceRecord.active === false) {
            return false;
          }
          items.editEvent = false; //! rempove possibility to show native edit event modal
          items.copyEvent = false;
          items.cutEvent = false;
          if (this.accessRightsPerResourceType[eventRecord.originalData.resourceType] === "full") {
            items.deleteEvent.onItem = () => self.handleDeleteEvent(eventRecord);
          } else {
            items.deleteEvent = false;
          }
        };
      }
      // remove context menu if user can't manage events
      if (!this.$can("update", "calendar")) {
        // if (
        //   namespace === "resourceScheduler" &&
        //   (!this.accessRightsPerResourceType[resourceType] ||
        //     this.accessRightsPerResourceType[resourceType] === "readonly")
        // ) {
        if (extendedConfig.features.scheduleMenu) {
          extendedConfig.features.scheduleMenu.disabled = true;
        }
        extendedConfig.features.eventMenu = false;
        extendedConfig.features.eventResize = false;
        extendedConfig.features.eventDrag = false;
        extendedConfig.createEventOnDblClick = false;
      }
      extendedConfig.features.eventDragCreate = false;
      // console.log("extendedConfig", resourceType, extendedConfig);
      return extendedConfig;
    },
    initScheduler(config, namespace, resourceType) {
      console.log("initScheduler", namespace);
      const extendedConfig = this.makeSchedulerConfig(config, namespace, resourceType);
      if (window[namespace] && !window[namespace].isDestroyed) {
        // save current view preset
        extendedConfig.viewPreset = window[namespace].viewPreset.id;
        window[namespace].destroy();
      }
      window[namespace] = new Scheduler(extendedConfig);
      // if (this.activeCalendar === "employee") {
      //   this.handleChangeVisibleRole();
      // }
    },
    makeProjectSchedulerConfig() {
      const extendedProjectConfig = Object.assign({}, projectConfig);
      if (this.projectMode) {
        extendedProjectConfig.tbar.unshift(
          { ...timeControls, id: "pr-time-controls" },
          { ...zoomControls, id: "pr-zoom-controls" }
        );
        extendedProjectConfig.columns[0].autoWidth = true;
      }
      extendedProjectConfig.listeners.beforeEventResizeFinalize = this.resizeEvent;
      // use custom modals to handle events edit
      extendedProjectConfig.listeners.beforeEventEdit = this.beforeEventEditHandler;
      extendedProjectConfig.listeners.toggleNode = this.handleExpandProject;

      // customize event context menu
      if (extendedProjectConfig.features.eventMenu) {
        extendedProjectConfig.features.eventMenu.processItems = ({ items, eventRecord, resourceRecord }) => {
          // Prevent menu for "locked" event
          if (eventRecord.locked) {
            return false;
          }
          items.editEvent = false;
          items.deleteEvent.onItem = () => this.handleDeleteEvent(eventRecord);
        };
      }
      extendedProjectConfig.features.scheduleTooltip = false;

      extendedProjectConfig.features.eventTooltip = {
        template: (data) => {
          if (data.eventRecord.isMetaEvent) {
            return undefined;
          }
          return data;
        },
      };
      extendedProjectConfig.eventRenderer = this.projectEventRenderer;
      // init with project configuration
      const extendedConfig = this.makeSchedulerConfig(extendedProjectConfig, "projectScheduler");
      console.log("extendedConfig", extendedConfig);
      return extendedConfig;
    },
    async initProject() {
      const config = this.makeProjectSchedulerConfig();
      window.projectScheduler = new Scheduler(config);
      // hide Detach button in projectMode
      if (this.projectMode) {
        window.projectScheduler.widgetMap.detach.hide();
      }
      if (window.resourceScheduler && !window.resourceScheduler.isDestroyed) {
        window.resourceScheduler.addPartner(window.projectScheduler);
      }
      console.log("initProject config:", config);
      await this.setupProjectSchedulerData("initProject");
      if (this.projectMode) {
        this.setupHolidayDateRanges(this.dataManager.viewDateRange);
        this.fitWidthForCalendarColumns();
      }
    },
    setupProjectSchedulerData(from) {
      return new Promise((res) => {
        console.log("setupProjectSchedulerData", from);
        const preparedProjectResources = this.prepareProjectResources();
        const projectRanges = this.makeProjectTimeRanges(preparedProjectResources);
        const allEvents = this.dataManager.gatherAllProjectEvents();
        const parentRowEvents = this.createParentRowEvents(preparedProjectResources);
        allEvents.push(...parentRowEvents);
        if (!window.projectScheduler || !window.projectScheduler.resourceStore) {
          return;
        }
        window.projectScheduler.resourceStore.data = preparedProjectResources;
        window.projectScheduler.resourceTimeRangeStore.data = projectRanges;
        window.projectScheduler.eventStore.data = allEvents;
        window.projectScheduler.resourceStore.clearFilters();

        setTimeout(() => {
          this.reCalculateProjectResourceRowHeight();
          if (window.projectScheduler) {
            window.projectScheduler.removeListener(PROJECT_REORDER_EVENT, this.handleReorderProjects);
            window.projectScheduler.addListener(PROJECT_REORDER_EVENT, this.handleReorderProjects);
          }
          res();
        }, 300);
      });
    },
    async setDateRange({ startDate, endDate }) {
      if (
        moment(startDate).isSame(this.dataManager.viewDateRange.startDate, "day") &&
        moment(endDate).isSame(this.dataManager.viewDateRange.endDate, "day")
      ) {
        return;
      }
      this.dataManager.setDateRange({ startDate, endDate });
      const resourceSchedulerReady = window.resourceScheduler && !window.resourceScheduler.isDestroyed;
      const projectSchedulerReady = window.projectScheduler && !window.projectScheduler.isDestroyed;

      try {
        if (resourceSchedulerReady) {
          window.resourceScheduler.mask("Laden...");
        }
        if (projectSchedulerReady) {
          window.projectScheduler.mask("Laden...");
        }
        await this.dataManager.fetchEvents(this.activeCalendar, startDate, endDate);
        if (projectSchedulerReady) {
          await this.setupProjectSchedulerData("setDateRange");
        }
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        if (window.resourceScheduler && window.resourceScheduler.unmask) {
          window.resourceScheduler.unmask();
        }
        if (projectSchedulerReady) {
          window.projectScheduler.unmask();
        }
        this.fitWidthForCalendarColumns();
      }
    },
    async handleReorderProjects(payload) {
      try {
        console.log("handleReorderProjects payload", payload);
        const { data: reorderedProjects } = await this.axios.post("/api/projects/reorder", {
          projectId: payload.projectId,
          newOrder: payload.newOrderValue,
        });
        await this.dataManager.fetchProjects();
        window.projectScheduler.resourceStore.beginBatch();
        reorderedProjects.forEach((item) => {
          const record = window.projectScheduler.resourceStore.getById(item.id);
          if (record) {
            record.set("order", item.order);
          }
        });
        window.projectScheduler.resourceStore.endBatch();
        if (window.resourceScheduler && !window.resourceScheduler.isDestroyed) {
          window.resourceScheduler.resourceStore.beginBatch();
          resourceScheduler.eventStore
            .query((item) => !!item.projectId)
            .forEach((eventModel) => {
              eventModel.set("order", this.dataManager.projects[eventModel.projectId].order);
            });
          window.resourceScheduler.resourceStore.endBatch();
        }
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    async checkForUpdates({ type, payload }) {
      if (this.preventNextCheck) {
        this.preventNextCheck = false;
        return;
      }
      const { startDate, endDate } = this.dataManager.viewDateRange;
      // if current calendar view range falls on one of ranges where events were updated - refetch events
      const upperCalendarViewRange = moment.range(startDate, endDate).snapTo("day");
      if (payload && payload.some((rangeString) => moment.range(rangeString).overlaps(upperCalendarViewRange))) {
        try {
          // update each resource calendar
          await this.dataManager.fetchEvents(this.activeCalendar, startDate, endDate);
          if (window.projectScheduler && !window.projectScheduler.isDestroyed) {
            await this.setupProjectSchedulerData("checkForUpdates");
          }
          if (window.resourceScheduler && !window.resourceScheduler.isDestroyed) {
            this.setupResourceCalendar(this.activeCalendar);
          }
        } catch (error) {
          throw error;
        }
      }
    },
    handleExpandProject(e) {
      const projectId = e.record.id;
      this.expandedProjects[projectId] = !e.collapse;
      // window.projectScheduler.widgetMap.minimize.show();
      // window.projectScheduler.widgetMap.expand.hide();
      this.$nextTick(() => {
        localStorage.setItem("EXPANDED_PROJECTS", JSON.stringify(this.expandedProjects));
      });
    },
    async handleCollapseProjects() {
      this.expandedProjects = this.dataManager.projectsList.reduce((obj, project) => {
        obj[project.id] = false;
        return obj;
      }, {});
      window.projectScheduler.resourceStore.beginBatch();
      this.dataManager.projectsList.forEach((project) => {
        const model = window.projectScheduler.resourceStore.getById(project.id);
        if (model) {
          model.set("expanded", false);
        }
      });
      window.projectScheduler.resourceStore.endBatch();
      await window.projectScheduler.collapseAll();
      await window.projectScheduler.widgetMap.minimize.hide();
      await window.projectScheduler.widgetMap.expand.show();
      localStorage.setItem("PROJECT_CALENDAR_COLLAPSED", "true");
      this.$nextTick(() => {
        localStorage.setItem("EXPANDED_PROJECTS", JSON.stringify(this.expandedProjects));
      });
    },
    async handleExtendProjects() {
      localStorage.removeItem("PROJECT_CALENDAR_COLLAPSED");
      this.expandedProjects = this.dataManager.projectsList.reduce((obj, project) => {
        obj[project.id] = true;
        return obj;
      }, {});
      window.projectScheduler.resourceStore.beginBatch();
      this.dataManager.projectsList.forEach((project) => {
        const model = window.projectScheduler.resourceStore.getById(project.id);
        if (model) {
          model.set("expanded", true);
        }
      });
      window.projectScheduler.resourceStore.endBatch();
      await window.projectScheduler.expandAll();
      await window.projectScheduler.widgetMap.minimize.show();
      await window.projectScheduler.widgetMap.expand.hide();
      this.$nextTick(() => {
        localStorage.setItem("EXPANDED_PROJECTS", JSON.stringify(this.expandedProjects));
      });
    },
    dismissProjectInfoModal() {
      this.projectInfoId = null;
    },
    reCalculateProjectResourceRowHeight() {
      if (!window.projectScheduler || window.projectScheduler.isDestroyed) {
        return;
      }
      // get heights of row elements
      const rowsData = Array.from(window.projectScheduler.currentElement.querySelectorAll(".b-grid-row")).map((el) => {
        return {
          resourceId: el.getAttribute("data-id"),
          height: el.offsetHeight,
        };
      });
      rowsData.forEach((row) => {
        const resourceApi = window.projectScheduler.resourceStore.getById(row.resourceId);
        if (row.resourceId.split("_").length === 1) {
          return;
        }
        if (resourceApi) {
          const eventsLength = resourceApi.events.length;
          let rowHeight = 38 * this.calendarSize.rowHeight;

          if (eventsLength > 10) {
            rowHeight = rowHeight * 0.8;
          } else if (eventsLength > 5) {
            rowHeight = rowHeight * 0.85;
          } else if (eventsLength > 3) {
            rowHeight = rowHeight * 0.9;
          }
          // // if row height is too high - reduce it to minimum
          // if (row.height > 80) {
          //   rowHeight = 20;
          // } else if (row.height > 60) {
          //   rowHeight = 25;
          // } else if (row.height > 40) {
          //   rowHeight = 30;
          // }
          resourceApi.rowHeight = rowHeight;
        }
      });
      window.projectScheduler.refreshRows();
    },
    async handleSetupProductGroups(resourceType) {
      if (resourceType === "employee") {
        return;
      }
      try {
        await this.dataManager.fetchProductGroups(resourceType);
        const pg1Items = this.dataManager.getProductGroups1();
        // set productgroup selection options into calendar
        if (window.resourceScheduler.widgetMap.pg1) {
          window.resourceScheduler.widgetMap.pg1.items = pg1Items;
        }
      } catch (error) {
        // reset productgroup selection items on error
        if (window.resourceScheduler.widgetMap.pg1) {
          window.resourceScheduler.widgetMap.pg1.items = [];
        }
        if (window.resourceScheduler.widgetMap.pg2) {
          window.resourceScheduler.widgetMap.pg2.items = [];
        }
        throw error;
      }
    },
    handleSelectPg2({ pg1 }) {
      let pg2Items = [];
      if (pg1) {
        pg2Items = this.dataManager.getProductGroups2(pg1);
      }
      window.resourceScheduler.widgetMap.pg2.items = pg2Items;
    },
    handleDiscardPg() {
      window.resourceScheduler.widgetMap.pg2.items = [];
      window.resourceScheduler.widgetMap.pg2.clear();
    },
    async setupChecklist(previousData) {
      try {
        const checklistWidget = window.resourceScheduler && window.resourceScheduler.widgetMap[`checklist`];
        if (checklistWidget) {
          if (!checklistWidget.menu) {
            const response = await this.axios.get("/api/checklists");
            if (checklistWidget.setData) {
              checklistWidget.setData(response.data);
            }
          }
          if (previousData) {
            checklistWidget.restoreState(previousData);
          }
        }
      } catch (error) {
        throw error;
      }
    },
    async setupResourceCalendar(resourceType) {
      if (this.projectMode) {
        return;
      }
      try {
        let checklistWidget;
        if (
          window.resourceScheduler &&
          window.resourceScheduler.widgetMap &&
          window.resourceScheduler.widgetMap["checklist"]
        ) {
          checklistWidget = window.resourceScheduler.widgetMap["checklist"];
        }
        const config = schedulerConfigMap[resourceType];
        console.log("config", config);
        if (this.isKioskMode) {
          config.columns[0].showImage = false;
        }
        const previousState = checklistWidget && { ...checklistWidget.currentState };
        this.initScheduler(schedulerConfigMap[resourceType], "resourceScheduler", resourceType);
        this.setupChecklist(previousState);
        const currentViewPreset = window.resourceScheduler.viewPreset.data.id;
        //! NOTE: this conditions are bounded to schedulerConfig topbar buttons.
        // Preserve zoom buttons state when switch between resources
        resourceScheduler.widgetMap.zoomInButton.disabled = currentViewPreset === "week_1";
        resourceScheduler.widgetMap.zoomOutButton.disabled = currentViewPreset === "week_4";
        window.resourceScheduler.mask({ text: "Laden..." });
        this.handleSetupProductGroups(resourceType);
        let resources = [];
        const eventColor = this.colorSettings[resourceType];
        if (resourceType === "employee") {
          resources = await this.dataManager.fetchEmployees(false, eventColor);
        } else if (resourceType === "machine") {
          resources = await this.dataManager.fetchMachines(eventColor);
        } else if (resourceType === "vehicle") {
          resources = await this.dataManager.fetchVehicles(eventColor);
        } else if (resourceType === "rhb") {
          resources = await this.dataManager.fetchRHB(eventColor);
        } else if (resourceType === "supply") {
          resources = await this.dataManager.fetchSupply(eventColor);
        } else if (resourceType === "subcontractor") {
          resources = await this.dataManager.fetchSubcontractor(eventColor);
        }
        window.resourceScheduler.resourceStore.data = resources;
        const resourceTimeRanges = this.makeResourceTimeRanges(resources);
        console.log("resourceTimeRanges", resourceTimeRanges);
        window.resourceScheduler.resourceTimeRangeStore.applyChangesetFilterSortTarget = "none";
        window.resourceScheduler.resourceTimeRangeStore.reapplyFilterOnAdd = true;
        window.resourceScheduler.resourceTimeRangeStore.reapplyFilterOnUpdate = true;
        window.resourceScheduler.resourceTimeRangeStore.add(resourceTimeRanges);
        // timeout is required for timeranges to be applied to all resources.
        // after that resources can be hidden
        setTimeout(() => {
          if (this.activeCalendar === "employee") {
            this.handleChangeVisibleRole();
          }
        }, 100);
        if (
          window.resourceScheduler &&
          !window.resourceScheduler.isDestroyed &&
          window.projectScheduler &&
          !window.projectScheduler.isDestroyed
        ) {
          window.resourceScheduler.addPartner(window.projectScheduler);
        }
        if (window.projectScheduler && !window.projectScheduler.isDestroyed) {
          setTimeout(() => {
            this.reCalculateProjectResourceRowHeight();
          }, 300);
        }
        window.resourceScheduler.unmask();
        this.fitWidthForCalendarColumns();
        if (this.isKioskMode) {
          // window.resourceScheduler.columns.first.showImage = false;
          const granularityWidget =
            window.resourceScheduler && window.resourceScheduler.getWidgetById("granularity_widget");
          if (granularityWidget) {
            granularityWidget.disabled = true;
          }
          const busyDaysFilter = window.resourceScheduler && window.resourceScheduler.getWidgetById("busyDaysFilter");
          if (busyDaysFilter) {
            busyDaysFilter.disabled = true;
            busyDaysFilter.hidden = true;
          }
        } else {
          const busyDaysFilter = window.resourceScheduler && window.resourceScheduler.getWidgetById("busyDaysFilter");
          if (busyDaysFilter) {
            busyDaysFilter.disabled = false;
            busyDaysFilter.hidden = false;
          } else {
            setTimeout(() => {
              const busyDaysFilter =
                window.resourceScheduler && window.resourceScheduler.getWidgetById("busyDaysFilter");
              if (busyDaysFilter) {
                busyDaysFilter.disabled = false;
                busyDaysFilter.hidden = false;
              }
            }, 2000);
          }
          const granularityWidget = resourceScheduler.getWidgetById("granularity_widget");
          if (granularityWidget) {
            granularityWidget.disabled = false;
          }
        }
        // displays Team under the resource name
        if (!this.isKioskMode && resourceType && resourceType !== "subcontractor") {
          this.handleShowTeams(Boolean(localStorage.getItem(`SHOW_TEAMS_${resourceType}`)), resourceType);
        }
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    // creates time ranges for "dateOfEntering" and "dateOfLeaving" properties
    makeResourceTimeRanges(resources) {
      // this date is used in case only one of dateOfEntering/dateOfLeaving properties is present
      const defaultStartDate = moment(0).toDate();
      const defaultEndDate = moment().add(2, "years").toDate();
      const timeRanges = resources.reduce((list, resource) => {
        if (resource.dateHistory) {
          // considering that records are always sorted ascendingly
          const lastIdx = resource.dateHistory.length - 1;
          resource.dateHistory.forEach((dateHistory, idx, allHistory) => {
            const resourceRange = {
              name: "Nicht verfügbar",
              resourceId: resource.id,
              startDate: null,
              endDate: null,
              eventColor: "red",
              readOnly: true,
              cls: "not-available",
            };
            if (idx === lastIdx) {
              if (dateHistory.dateOfLeaving) {
                list.push(
                  Object.assign({}, resourceRange, {
                    startDate:
                      idx === 0 ? defaultStartDate : moment(get(allHistory[idx - 1], "dateOfLeaving")).toDate(),
                    endDate: moment(dateHistory.dateOfEntering).startOf("day").toDate(),
                  })
                );
                list.push(
                  Object.assign({}, resourceRange, {
                    startDate: moment(dateHistory.dateOfLeaving).startOf("day").toDate(),
                    endDate: defaultEndDate,
                  })
                );
              } else {
                list.push(
                  Object.assign({}, resourceRange, {
                    startDate: moment(get(allHistory[idx - 1], "dateOfLeaving", defaultStartDate))
                      .startOf("day")
                      .toDate(),
                    endDate: moment(dateHistory.dateOfEntering).startOf("day").toDate(),
                  })
                );
              }
            } else {
              list.push(
                Object.assign({}, resourceRange, {
                  startDate: moment(get(allHistory[idx - 1], "dateOfLeaving", defaultStartDate))
                    .startOf("day")
                    .toDate(),
                  endDate: moment(dateHistory.dateOfEntering).startOf("day").toDate(),
                })
              );
            }
          });
        }
        return list;
      }, []);
      return timeRanges;
    },
    openQuickProjectSetup() {
      // intercepted at quick-project-setup modal
      this.$root.$emit("openQuickProjectSetup");
    },
    /**
     * Navigates calendar to date passed via query params, then removes "date" from query params.
     */
    handleSetDateFromQuery() {
      // date to navigate calendar view
      const query = Object.assign({}, this.$route.query);
      const date = query.date;
      if (date) {
        delete query.date;
        this.$router.replace({ query });
        const startDate = moment(date).startOf("week").toDate();
        const endDate = moment(date).endOf("week").toDate();
        this.dataManager.setDateRange({ startDate, endDate });
      }
    },
    async triggerCalendarUpdate() {
      this.preventNextCheck = true;
      await this.dataManager.fetchEvents(
        this.activeCalendar,
        this.dataManager.viewDateRange.startDate,
        this.dataManager.viewDateRange.endDate
      );
      if (window.projectScheduler) {
        const preparedProjectResources = this.prepareProjectResources();
        const allEvents = this.dataManager.gatherAllProjectEvents();
        const parentRowEvents = this.createParentRowEvents(preparedProjectResources);
        allEvents.push(...parentRowEvents);
        window.projectScheduler.eventStore.data = allEvents;
      }
      if (window.resourceScheduler.resourceStore.filters.get("available_days_filter")) {
        window.resourceScheduler.resourceStore.removeFilter("available_days_filter");
        window.resourceScheduler.trigger("filter_busy_resources", {
          days: window.filterUpperCalendarResourcesDays,
        });
      }
    },
    ignoreNextUpdateCheck() {
      this.preventNextCheck = true;
    },
    async setupHolidayDateRanges(payload) {
      const response = await this.axios.get("/api/public-holidays/calendar", {
        params: { start: payload.startDate, end: payload.endDate },
      });
      await this.axios.get("/api/public-holidays/calendar/mobile", {
        params: {
          start: moment(payload.startDate).format("YYYY-MM-DD"),
          end: moment(payload.endDate).subtract(1, "hour").format("YYYY-MM-DD"),
        },
      });
      const holidayRanges = response.data.map((item) => ({
        startDate: moment(item.start).startOf("day").toDate(),
        endDate: moment(item.end).endOf("day").toDate(),
        name: item.name,
        style: `background: ${item.color}; opacity: 0.5`,
      }));
      if (window.resourceScheduler) {
        window.resourceScheduler.timeRangeStore.add(holidayRanges);
      }
      if (window.projectScheduler && window.projectScheduler.timeRangeStore) {
        window.projectScheduler.timeRangeStore.add(holidayRanges);
      }
    },
    fitWidthForCalendarColumns() {
      const reduceFn = (width, column) => {
        if (column.type !== "timeAxis") {
          const calculatedWidth = column.resizeToFitContent();
          width += calculatedWidth;
        }
        return width;
      };
      const resourceColumnsWidth = window.resourceScheduler
        ? window.resourceScheduler.columns.reduce(reduceFn, 0)
        : undefined;
      const projectColumnsWidth =
        window.projectScheduler && window.projectScheduler.columns
          ? window.projectScheduler.columns.reduce(reduceFn, 0)
          : undefined;
      const maximumWidth = Math.max(resourceColumnsWidth || 0, projectColumnsWidth + 50);
      if (!isNaN(maximumWidth)) {
        setTimeout(() => {
          window.projectScheduler.columns.first.width = maximumWidth;
        }, 100);
      }
    },
    dismissEventComment() {
      this.eventCommentVisible = false;
      this.eventCommentData = {
        eventId: null,
        comment: null,
        resourceType: null,
      };
    },
    dismissCopyResources() {
      this.copyResourcesVisible = false;
      this.copyResourcesProject = {
        id: null,
        dateRange: null,
        name: null,
      };
    },
    isNoEditAccess(resourceType) {
      return (
        !this.accessRightsPerResourceType[resourceType] || this.accessRightsPerResourceType[resourceType] === "readonly"
      );
    },
    isDisabledStatusEvents(resourceType) {
      if (!this.accessRights[resourceType] || this.isKioskMode) {
        return true;
      }
      const rights = this.accessRights[resourceType].specificAccess;
      return rights ? rights.status === null || rights.status === "readonly" : true;
    },
    getCanCreateNewStatusTypes(resourceType) {
      const rights = get(this.accessRights, `company_settings.specificAccess.${resourceType}`, null);
      if (!rights) {
        return false;
      }
      return rights === "manage" || rights === "full";
    },
    handleShowTeams(show, resourceType) {
      let rowHeight, showMetaFn;
      if (show) {
        showMetaFn = (record) => record.team || "";
        rowHeight = 35;
      } else {
        showMetaFn = () => "";
        rowHeight = 25;
      }
      const widgetName = `showTeams_${resourceType}`;
      const widget = window.resourceScheduler.widgetMap[widgetName];
      if (widget) {
        widget.value = show;
      }
      window.resourceScheduler.columns.getAt(0).setData("showMeta", showMetaFn);
      window.resourceScheduler.rowHeight = rowHeight;
    },
    getAvailableProjects(day) {
      const monday = moment(day).isoWeekday(1);
      const sunday = moment(day).isoWeekday(7).endOf("day");
      const viewDateRange = moment.range(monday, sunday);
      return sortBy(
        this.dataManager.projectsSelection.reduce((array, project) => {
          if (!project.active) {
            return array;
          }
          const projectDateRange = moment.range(project.dateRange).snapTo("day");
          if (viewDateRange.intersect(projectDateRange)) {
            array.push(project);
          }
          return array;
        }, []),
        [(r) => r.isWorkshop, (r) => r.text.toLowerCase()]
      );
    },
    updateProjectHotelBooking(payload, action) {
      let projectId;
      if (action === "delete") {
        this.dataManager.deleteProjectHotelBooking(payload);
        projectId = payload.projectId;
      } else if (action === "delete_all") {
        this.dataManager.deleteProjectHotelBooking(payload, true);
        projectId = payload.projectId;
      } else if (action === "edit") {
        this.dataManager.updateProjectHotelBooking(payload);
        projectId = payload.projectId;
      } else if ("create") {
        this.dataManager.createProjectHotelBookings(payload);
        projectId = payload[0].projectId;
      }
      const sysEventsOfProject = window.projectScheduler.eventStore.reduce((list, evt) => {
        if (evt.id.startsWith(`sys_${projectId}`)) {
          list.push(evt);
        }
        return list;
      }, []);
      window.projectScheduler.eventStore.remove(sysEventsOfProject);
      const newSysEvents = this.makeWrapperEventsForProject(this.dataManager.projects[projectId]);
      window.projectScheduler.eventStore.add(newSysEvents);
    },
  },
  computed: {
    ...mapGetters("customerSettings", ["colorSettings"]),
    ...mapState("account", { accessRights: "accessRights", accessRightsLoaded: "accessRightsLoaded" }),
    ...mapGetters("granularitySettings", { granularitySettings: "data" }),
    eventsCanOverlap() {
      return this.granularitySettings[this.activeCalendar] === "CONCURRENT";
    },
    calendarAccess() {
      return get(this.accessRights, "calendar", { specificAccess: {}, generalAccess: null });
    },
    canAddComments() {
      const access = this.calendarAccess.generalAccess;
      if (this.isKioskMode) {
        return false;
      }
      return access === "full" || access === "manage";
    },
    hasAccessToInvoices() {
      const projectAccessRights = get(this.accessRights, "project", {});
      return (
        !!get(projectAccessRights, "generalAccess", false) &&
        !!get(projectAccessRights, "specificAccess.invoices", false)
      );
    },
    isKioskMode() {
      return this.$route.query.kiosk === "true";
    },
    isAllChecked() {
      return !Object.values(this.projectResourceFilters).every(Boolean);
    },
    isAllIntermediate() {
      const allResourcesFlags = Object.values(this.projectResourceFilters);
      const allResourcesLength = allResourcesFlags.length;
      const checkedResourcesLength = allResourcesFlags.filter(Boolean).length;
      return checkedResourcesLength < allResourcesLength && checkedResourcesLength > 0;
    },
    projectMode() {
      return !!this.$route.meta.projectMode || this.$route.query.detachMode === "true";
    },
    dropdownLabel() {
      return resourceLabelMap[this.activeCalendar];
    },
    selectedRoles() {
      const rolesString = Object.entries(this.employeeRoleFilters)
        .reduce((roles, [role, selected]) => {
          if (selected) {
            roles.push(role);
          }
          return roles;
        }, [])
        .join(", ");
      if (rolesString) {
        return `: ${rolesString}`;
      } else {
        return "";
      }
    },
    accessRightsPerResourceType() {
      return {
        employee: this.calendarAccess.specificAccess.employeeCalendarAccess,
        vehicle: this.calendarAccess.specificAccess.vehicleCalendarAccess,
        machine: this.calendarAccess.specificAccess.machineCalendarAccess,
        rhb: this.calendarAccess.specificAccess.rhbCalendarAccess,
        supply: this.calendarAccess.specificAccess.supplyCalendarAccess,
        subcontractor: this.calendarAccess.specificAccess.subcontractorCalendarAccess,
      };
    },
  },
  watch: {
    accessRights(newVal) {
      if (newVal && newVal[this.activeCalendar]) {
        const accessRights = newVal[this.activeCalendar];
        if (window.resourceScheduler) {
          const noStatusAccessRights =
            !!accessRights.specificAccess ||
            (accessRights.specificAccess.status !== "manage" && accessRights.specificAccess.status !== "full");
          if (
            noStatusAccessRights &&
            window.resourceScheduler.features.scheduleMenu &&
            window.resourceScheduler.features.scheduleMenu.items
          ) {
            window.resourceScheduler.features.scheduleMenu.items.addStatusEvent = false;
          }
        }
      }
    },
    // observes dropdown change and handles scheduler re-initialization
    activeCalendar(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setupResourceCalendar(newVal);
        const accessRights = this.accessRights[newVal];
        if (window.resourceScheduler) {
          const noStatusAccessRights =
            !accessRights.specificAccess ||
            (accessRights.specificAccess.status !== "manage" && accessRights.specificAccess.status !== "full");
          if (noStatusAccessRights) {
            window.resourceScheduler.features.scheduleMenu.items.addStatusEvent = false;
          }
        }
      }
    },
    isSyncViewDate(newVal) {
      if (newVal && this.projectMode) {
        this.handleTimeAxisChange({ config: { ...this.dataManager.viewDateRange } });
      }
    },
    "calendarSize.textSize": function (newVal, oldVal) {
      const calendarRoot = document.getElementById("scheduler-container");
      switch (newVal) {
        case "sm":
          calendarRoot.style.fontSize = "10px";
          document.body.classList.remove("md", "lg");
          document.body.classList.add("sm");
          document.documentElement.style.setProperty("--event-font-size", "10px");
          break;
        case "md":
          document.body.classList.remove("sm", "lg");
          document.body.classList.add("md");
          calendarRoot.style.fontSize = "14px";
          document.documentElement.style.removeProperty("--event-font-size");
          break;
        case "lg":
          document.body.classList.remove("md", "sm", "lg");
          document.body.classList.add("lg");
          calendarRoot.style.fontSize = "18px";
          document.documentElement.style.setProperty("--event-font-size", "18px");
          break;
        default:
          document.body.classList.remove("md");
          document.body.classList.remove("sm");
          document.body.classList.remove("lg");
          calendarRoot.style.fontSize = "14px";
          document.documentElement.style.removeProperty("--event-font-size");
          break;
      }
      localStorage.setItem("CAL_TEXT_SIZE", newVal);
      this.setupProjectSchedulerData("calendarSize");
      this.setupResourceCalendar(this.activeCalendar);
    },
    "calendarSize.rowHeight": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (window.resourceScheduler) {
          window.resourceScheduler.rowHeight = 30 * newVal;
        }
        if (window.projectScheduler) {
          this.reCalculateProjectResourceRowHeight();
        }
        localStorage.setItem("CAL_ROW_HEIGHT", newVal);
        this.setupProjectSchedulerData("calendarSize");
        this.setupResourceCalendar(this.activeCalendar);
      }
    },
    granularitySettings: {
      handler(newVal) {
        const granularityWidget =
          window.resourceScheduler && window.resourceScheduler.getWidgetById("granularity_widget");
        if (granularityWidget) {
          const resourceType = granularityWidget.resourceType;
          if (resourceType) {
            granularityWidget.setMode(newVal[resourceType]);
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@import "./styles/calendar.scss";
</style>
